import React, { PureComponent } from 'react';
import { errorValues, inputClassNames, hasDisabledStatus } from './utils';

export default class Text extends PureComponent {
  render() {
    const {
      label,
      min,
      type,
      placeholder,
      defaultValue,
      maxLength,
      disabled,
      field,
      note,
      form: { status }
    } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);
    const isDisabledStatus = hasDisabledStatus(status, field.name);
    const inputClasses = inputClassNames(this.props, 'form-control');

    return (
      <div className={errorClass}>
        <label>{label}</label>
        {note && <div style={{fontSize: "smaller", fontWeight: "lighter", marginBottom: "5px"}}>{note}</div>}
        <div>
          <input
            {...field}
            defaultValue={defaultValue}
            disabled={disabled || isDisabledStatus}
            maxLength={maxLength}
            min={min}
            placeholder={placeholder ? placeholder : label}
            type={type}
            value={field.value || ''}
            className={inputClasses}
          />
          {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
        </div>
      </div>
    );
  }
}
