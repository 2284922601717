module.exports.NetworkStatus = {
  ONLINE: 'Online',
  READY: 'Ready',
  OFFLINE: 'Offline'
};

module.exports.DATE_FORMAT = 'MM-DD-YY, h:mma';
module.exports.DATE_FORMAT_FNS = 'mm-DD-yy, h:mma';
module.exports.DATE_FORMAT_NOTIFICATION = 'MM-DD-YY, hh:mma';
module.exports.DATE_FORMAT_TIME_ONLY = 'hh:mma';
module.exports.DATE_ONLY_FORMAT = 'MM-DD-YY';
module.exports.DATE_ONLY_BACKSLASH = 'M/DD/YYYY';

module.exports.SubmitTypes = {
  submit: 'Submit',
  test: 'Test',
  brightreeSubmit: 'Submit'
};

module.exports.DocumentTypes = {
  ADVOCACY: 'patient_asst',
  ABN: 'abn',
  CMN: 'cms_cmn',
  PAIR: 'pair',
  DEMO_PAIR: 'demo_pair',
  TPL: 'tpl'
};

module.exports.NoteTypes = {
  GENERAL_TYPE: 1,
  IR_TYPE: 2,
  IPC: 9,
  PFS_TYPE: 3,
  FULFILLMENT_TYPE: 4,
  OM_NOTE: 5,
  REP_NOTE: 6,
  TRUNK_STOCK_REQUESTED: 7,
  TRUNK_STOCK_CANCELED: 8,
  IPC: 9,
  DOS_NOTE: 10,
  SHIPPING_INFO: 11,
  RC_NOTE: 12,
  MANAGER_EOS_NOTE: 13,
  ATM_NOTE: 14,
  ORDER_CANCELLED_NOTE: 15,
  TM_FINANCIAL_CONVERSATION: 16,
  ALERT_PFS_NOTE: 17
};

module.exports.DOCUMENT_PENDING = 'pending';
module.exports.DOCUMENT_UPLOADED = 'uploaded';
module.exports.DOCUMENT_AVAILABLE = 'available';
module.exports.DOCUMENT_EMAILED = 'emailed';
module.exports.DOCUMENT_NEEDS_REVIEW = 'needs-review';
module.exports.DOCUMENT_DENIED = 'denied';
module.exports.DOCUMENT_APPROVED = 'approved';

module.exports.DocumentStatus = {
  [module.exports.DOCUMENT_PENDING]: 'Pending',
  [module.exports.DOCUMENT_UPLOADED]: 'Uploaded',
  [module.exports.DOCUMENT_AVAILABLE]: 'Available',
  [module.exports.DOCUMENT_EMAILED]: 'Emailed',
  [module.exports.DOCUMENT_NEEDS_REVIEW]: 'Needs Review',
  [module.exports.DOCUMENT_DENIED]: 'Denied',
  [module.exports.DOCUMENT_APPROVED]: 'Approved'
};

module.exports.StatusMap = {
  open: ['open', 'submitted', 'pending'],
  completed: ['invoiced', 'completed'],
  cancelled: ['cancelled', 'cancel-pending'],
  voided: ['voided']
};

module.exports.NoteTypesArr = [
  { id: 1, display: 'General' },
  { id: 2, display: 'RS/PFS' },
  { id: 3, display: 'PFS' },
  { id: 4, display: 'Fulfillment' },
  { id: 5, display: 'OM Note' },
  { id: 6, display: 'Rep Note' },
  { id: 7, display: 'Trunk Stock Requested' },
  { id: 8, display: 'Trunk Stock Canceled' },
  { id: 9, display: 'IPC' },
  { id: 10, display: 'DOS Note' },
  { id: 11, display: 'Shipping Info' },
  { id: 12, display: 'RC Note' },
  { id: 13, display: 'Manager EOS Note' },
  { id: 14, display: 'ATM Note' },
  { id: 15, display: 'Order Cancelled Note' },
  { id: 16, display: 'TM Financial Conversation' },
  { id: 17, display: 'Alert PFS Note' }
];

module.exports.InternalDocusignStatus = {
  Created: 'Created',
  InProgress: 'In-progress',
  Canceled: 'Canceled',
  Completed: 'Completed'
};

module.exports.Groups = {
  BioventusSales: 'Bioventus Sales',
  SystemsAdmin: 'Systems Admin',
  DistributorAdmin: 'Distributor Admin',
  User: 'User',
  DocumentAdmin: 'Document Admin',
  NewsAdmin: 'News Admin',
  OrdersAndInventory: 'Orders and Inventory',
  OrderProcessing: 'Order Processing',
  UserManagement: 'User Management',
  CompanyManagement: 'Company Management',
  SapClient: 'SAP Client',
  ExogenDirect: 'ExogenDirect',
  IR: 'IR',
  DocusignCmn: 'Docusign CMN',
  DocusignPair: 'Docusign PAIR',
  DocusignAbn: 'Docusign ABN',
  DocusignPaf: 'Docusign PAF',
  ExogenTrain: 'ExogenTrain',
  ExogenLearn: 'ExogenLearn',
  BV360Access: 'BV360',
  EmailCmn: 'Email CMN',
  BV360CustomerService: 'BV360 Customer Service',
  ExogenOrderManagementMode: 'Exogen Order Management Mode',
  ExogenOrderManager: 'Exogen Order Manager',
  ExogenSalesRep: 'ExogenSR',
  ExogenATM: 'ExogenATM',
  ExogenMom: 'ExogenMOM',
  EOSLead: 'EOS Lead',
  ExogenSl: 'ExogenSL',
  ExogenRC: 'ExogenRC',
  ExogenMDM: 'ExogenMDM',
  ExogenDOS: 'ExogenDOS',
  ExogenExecutive: 'ExogenExecutive'
};

module.exports.Permissions = {
  Demo_Pair: 'exogen.can_demo_pair',
  ABN: 'exogen.can_abn',
  EmailCmn: 'exogen.initiate_email_cmn',
  EsignCmn: 'exogen.esign_cmn',
  RequestHelp: 'exogen.initiate_help',
  ViewAdminOrder: 'exogen.view_admin_order',
  CreateDraft: 'exogen.can_create_draft',
  DeleteDraft: 'exogen.can_delete_draft',
  SubmitOrder: 'exogen.can_submit_order',
  CanFulfill: 'exogen.can_initiate_fulfillment',
  CanUpload: 'exogen.can_upload_document',
  CreateDocusign: 'exogen.can_create_docusign',
  CanRequestTrunkStock: 'exogen.can_initiate_fitting',
  CreateOrderSalesNote: 'exogen.can_create_order_sales_note',
  CreateOrderAtmNote: 'exogen.can_create_order_atm_note',
  CreateOrderOMNote: 'exogen.can_create_order_om_note',
  CreateOrderGeneralNote: 'exogen.can_create_order_general_note',
  CreateOrderRSNote: 'exogen.can_create_order_rs_note',
  CreateOrderPFSNote: 'exogen.can_create_order_pfs_note',
  CreateDraftSalesNote: 'exogen.can_create_draft_sales_note',
  CreateDraftAtmNote: 'exogen.can_create_draft_atm_note',
  CreateDraftOMNote: 'exogen.can_create_draft_om_note',
  CreateDraftGeneralNote: 'exogen.can_create_draft_general_note',
  CanInitiatePAF: 'exogen.initiate_paf',
  UpdateDeliveryAddress: 'exogen.update_delivery_address',
  CanViewOfficeVisitDashboard: 'exogen.can_view_office_visit_dashboard',
  CanAddDraftIncompleteReason: 'exogen.can_add_draft_incomplete_reason',
  CanAddReminder: 'exogen.add_reminder',
  CanViewReminder: 'exogen.view_reminder',
  CanChangeReminder: 'exogen.change_reminder',
  EditPatientContact: 'exogen.can_edit_initial_patient_contact',
  CanCancelOrder: 'exogen.can_cancel_order',
  CanUseNotes: 'exogen.can_use_notes',
  CanAddNote: 'exogen.add_note',
  CanUseNoteTemplates: 'exogen.can_use_note_templates',
  CanAccessPushSettings: 'exogen.can_access_push_settings',
  CanModifyPushNotificationPreference: 'exogen.can_modify_push_notification_preference',
  CanSendPatientIntroEmail: 'exogen.can_send_patient_intro_email',
  CanUseFakeFill: 'exogen.can_use_fake_fill',
  CanEditPatientEmailInOrder: 'exogen.can_edit_patient_email_in_order'
};

module.exports.OmRoles = {
  Om: 'OrderManager',
  Rep: 'Rep',
  DoS: 'DirectorOfSales'
};

module.exports.ReferralOptions = [
  { label: 'Financial Conversation', value: 'financial-conversation', id: 8 },
  {
    label: 'Please bill at risk (option for denied orders or Evidence Street)',
    value: 'bill-at-risk',
    id: 9
  },
  { label: 'Close as self-pay', value: 'close-as-self-pay', id: 10 },
  {
    label: 'Patient has expressed financial hardship',
    value: 'patient-express-financial-hardship',
    id: 11
  },
  { label: 'Do not ship unit', value: 'do-not-ship-unit', id: 12 }
];

module.exports.DiagnosisSignedRecordOnFile = [
  { value: 'Yes: Electronically Signed', label: 'Yes: Electronically Signed' },
  { value: 'Yes: Wet Signed', label: 'Yes: Wet Signed' },
  { value: 'No', label: 'No' }
];

module.exports.DiagnosisMultipleViewsNoted = [
  { value: 'Yes: Copy of imaging Report', label: 'Yes: Copy of imaging Report' },
  {
    value: 'Yes: Physician dictated multiple views',
    label: 'Yes: Physician dictated multiple views'
  },
  {
    value: 'Yes: Physician dictated views taken (i.e. AP Lateral)',
    label: 'Yes: Physician dictated views taken (i.e. AP Lateral)'
  },
  { value: 'No', label: 'No' }
];

module.exports.AdditionalEquipment = [
  { value: '', label: 'None' },
  {
    value: 'weighted-strap',
    label: 'Weighted Strap'
  },
  {
    value: 'metatarsal-strap',
    label: 'Metatarsal Strap'
  },
  {
    value: 'extended-strap',
    label: 'Extended Strap'
  }
];

module.exports.States = [
  { id: 'AL', name: 'Alabama' },
  { id: 'AK', name: 'Alaska' },
  { id: 'AZ', name: 'Arizona' },
  { id: 'AR', name: 'Arkansas' },
  { id: 'CA', name: 'California' },
  { id: 'CO', name: 'Colorado' },
  { id: 'CT', name: 'Connecticut' },
  { id: 'DE', name: 'Delaware' },
  { id: 'DC', name: 'District Of Columbia' },
  { id: 'FL', name: 'Florida' },
  { id: 'GA', name: 'Georgia' },
  { id: 'HI', name: 'Hawaii' },
  { id: 'ID', name: 'Idaho' },
  { id: 'IL', name: 'Illinois' },
  { id: 'IN', name: 'Indiana' },
  { id: 'IA', name: 'Iowa' },
  { id: 'KS', name: 'Kansas' },
  { id: 'KY', name: 'Kentucky' },
  { id: 'LA', name: 'Louisiana' },
  { id: 'ME', name: 'Maine' },
  { id: 'MD', name: 'Maryland' },
  { id: 'MA', name: 'Massachusetts' },
  { id: 'MI', name: 'Michigan' },
  { id: 'MN', name: 'Minnesota' },
  { id: 'MS', name: 'Mississippi' },
  { id: 'MO', name: 'Missouri' },
  { id: 'MT', name: 'Montana' },
  { id: 'NE', name: 'Nebraska' },
  { id: 'NV', name: 'Nevada' },
  { id: 'NH', name: 'New Hampshire' },
  { id: 'NJ', name: 'New Jersey' },
  { id: 'NM', name: 'New Mexico' },
  { id: 'NY', name: 'New York' },
  { id: 'NC', name: 'North Carolina' },
  { id: 'ND', name: 'North Dakota' },
  { id: 'OH', name: 'Ohio' },
  { id: 'OK', name: 'Oklahoma' },
  { id: 'OR', name: 'Oregon' },
  { id: 'PA', name: 'Pennsylvania' },
  { id: 'RI', name: 'Rhode Island' },
  { id: 'SC', name: 'South Carolina' },
  { id: 'SD', name: 'South Dakota' },
  { id: 'TN', name: 'Tennessee' },
  { id: 'TX', name: 'Texas' },
  { id: 'UT', name: 'Utah' },
  { id: 'VT', name: 'Vermont' },
  { id: 'VA', name: 'Virginia' },
  { id: 'WA', name: 'Washington' },
  { id: 'WV', name: 'West Virginia' },
  { id: 'WI', name: 'Wisconsin' },
  { id: 'WY', name: 'Wyoming' }
];

module.exports.RuleTypes = {
  requiredDocs: 'required_docs'
};

module.exports.OrderScrollPoints = [
  'patient',
  'payer',
  'diagnosis',
  'physician',
  'notes',
  'documents',
  'fulfillment',
  'sap & crm'
];

module.exports.WipStates = {
  SERIAL_NUMBER_ISSUE: '5.5 Serial Number Issue',
  READY_FOR_TRUNK_STOCK: '5.4 Ready for Trunk Stock',
  DOC_REVIEW_BENEFITS_VERIFICATION_1_0: '1.0 Initial Doc Review & Benefits Verification',
  INCOMPLETE_INITIAL_ORDER_DOCUMENTATION_1_1: '1.1 Incomplete Initial Order Documentation',
  BENEFIT_VERIFICATION_PENDING_RESPONSE_1_2: '1.2 Benefit Verification Pending Response',
  INTERNAL_REVIEW_PRE_AUTH_SUBMISSION_2_0: '2.0 Internal Review & Pre-Auth Submission',
  PENDING_ADDITIONAL_DOCS_OM_RESPONSE_2_1: `2.1 Pending Add'l Documentation/ OM Response`,
  HOLD_ORDER_OOG_2_2: '2.2 Hold Order: OOG',
  PENDING_PAR_RECEIVED_PAYER_2_3: '2.3 Pending PAR Received by Payer',
  RS_FOLLOW_UP_3_0: '3.0 RS Follow-Up',
  PENDING_CMN_3_1: '3.1 Pending CMN',
  PENDING_MEDICARE_CHECKLIST_3_2: '3.2 Pending Medicare Checklist Completion',
  PENDING_MEDICARE_SECONDARY_REVIEW_3_3: '3.3 Pending Medicare Secondary Review',
  MEDICARE_SECONDARY_REVIEW_COMPLETE_3_4: '3.4 Medicare Secondary Review Complete',
  PENDING_PRE_AUTH_APPEAL_3_5: '3.5 Pending Pre-Auth, Appeal, or P2P',
  PATIENT_FINANCIAL_CONVO_4_0: '4.0 Patient Financial Conversation',
  FIRST_CALL_PENDING_PATIENT_RESPONSE_4_1: '4.1 First Call Attempt - Pending Patient Response',
  SECOND_CALL_OM_ASSISTANCE_REQUIRED_4_2: '4.2 Second Call Attempt - OM Assistance Requested',
  PENDING_CLINIC_VISIT_OR_PRE_AUTH_4_3: '4.3 Pending Clinic Visit or Pre-Auth Decision',
  REVIEW_NEW_INFO_4_4: '4.4 Review New Information',
  READY_TO_PROCEED_FORWARD_4_5: '4.5 Ready to Proceed Forward',
  PFS_MANAGER_REVIEW_4_6: '4.6 PFS Manager Review',
  DELIVERY_AND_CONFIRMATION_5_0: '5.0 Delivery & Confirmation',
  REQUIREMENTS_NOT_MET_5_1: '5.1 Requirements Not Met',
  READY_FOR_SECOND_REVIEW_5_2: '5.2 Ready for Second Review',
  READY_TO_SHIP_5_3: '5.3 Ready to Ship',
  READY_FOR_TRUNK_STOCK_5_4: '5.4 Ready for Trunk Stock',
  SERIAL_NUMBER_ISSUE_5_5: '5.5 Serial Number Issue',
  TRUNK_STOCK_DELIVERY_CONFIRMED_5_6: '5.6 Trunk Stock Delivery Confirmed',
  ERROR_SAP_CANNOT_CREATE_ORDER_5_7: '5.7 Error: SAP Cannot Create Order',
  RECEIVED_BY_SAP_5_8: '5.8 Received by SAP',
  POD_RECEIVED_5_9: '5.9 POD Received',
  CONFIRMATION_PENDING_PRE_AUTH_6_0: '6.0 Confirmation Pending Pre-Auth',
  ESCALATION_REVIEW_7_0: '7.0 Escalation Review',
  OM_MANAGER_REVIEW_7_1: '7.1 OM Manager Review',
  HOLD_FOR_CANCELLATION_CONSIDERATION_7_2: '7.2 Hold for Cancellation Consideration'
};
