import React from 'react';
import { errorValues, inputClassNames } from './utils';
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export default class PhoneField extends React.PureComponent {
  handleChange = event => {
    const { field, form, showPhoneControls } = this.props;
    const cleanValue = this.stripCharacters(event.target.value);
    form.setFieldValue(field.name, cleanValue);
  };

  stripCharacters = string => {
    const re = new RegExp(/[()\- ]+/, 'g');
    return string.replace(re, '');
  };

  render() {
    const { placeholder, label, field, disabled, showValidation } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);
    const classes = showValidation ? inputClassNames(this.props, 'form-control') : 'form-control';

    return (
      <div className={errorClass}>
        <label>
          {label}{' '}
          {navigator.userAgent.indexOf('gonative') > -1 && this.props.showPhoneControls && (
            <>
              <a href={`tel:${field.value}`}>
                <i className="fa fa-phone-square fa-fw" /> Call
              </a>{' '}
              <a href={`sms:${field.value}`}>
                <i className="fa fa-phone-square fa-fw" /> SMS
              </a>
            </>
          )}{' '}
        </label>
        <div>
          <MaskedInput
            mask={phoneNumberMask}
            {...field}
            disabled={disabled}
            id={field.name}
            placeholder={placeholder}
            onChange={this.handleChange}
            className={classes}
            type="text"
          />
          {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
        </div>
      </div>
    );
  }
}
