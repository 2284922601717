import React, { PureComponent } from 'react';
import { errorValues } from './utils';
import Autosuggest from 'react-autosuggest';

export default class AutoSuggestField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: []
    };
  }

  handleSelected = evt => evt.preventDefault();

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.form.setFieldValue(this.props.field.name, newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { items } = this.props;
    this.setState({
      suggestions: this.props.getSuggestions(items, value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { field, label, placeholder, disabled } = this.props;

    const { value, suggestions } = this.state;

    const inputProps = {
      ...field,
      placeholder: placeholder ? placeholder : 'Type...',
      onChange: this.onChange,
      value: field.value ? field.value : '',
      disabled
    };

    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);

    return (
      <div className={errorClass}>
        <label>{label}</label>
        <div>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.props.getSuggestionValue}
            renderSuggestion={this.props.renderSuggestion}
            onSuggestionSelected={this.handleSelected}
            inputProps={inputProps}
            className="form-control"
          />
          {fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}
        </div>
      </div>
    );
  }
}
