import React, { PureComponent } from 'react';
import GenericField from './generic-field';
import { errorValues, required } from './utils';
import { Field } from 'formik';

export default class RadioGroupField extends PureComponent {
  render() {
    const {
      field,
      label,
      elems,
      disabled,
      validateUntouched,
      onChange = null,
      inline = true
    } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);

    return (
      <div className={errorClass}>
        {label && <label>{label}</label>}
        {inline && (
          <div className="radio-group">
            {elems.map((elem, idx) => (
              <span key={idx}>
                <Field
                  name={field.name}
                  type="radio"
                  component={GenericField}
                  onChange={
                    onChange
                      ? val => {
                          onChange(val);
                          field.onChange(val);
                        }
                      : field.onChange
                  }
                  disabled={disabled}
                  label={elem.label}
                  value={elem.value}
                  validate={required}
                />{' '}
              </span>
            ))}
          </div>
        )}

        {!inline && (
          <div className="radio-group">
            {elems.map((elem, idx) => (
              <div key={idx}>
                <Field
                  name={field.name}
                  type="radio"
                  component={GenericField}
                  onChange={
                    onChange
                      ? val => {
                          onChange(val);
                          field.onChange(val);
                        }
                      : field.onChange
                  }
                  disabled={disabled}
                  label={elem.label}
                  value={elem.value}
                  validate={required}
                />{' '}
              </div>
            ))}
          </div>
        )}

        <div>
          {(fieldTouched || validateUntouched) && fieldError && (
            <span className="has-error">{fieldError}</span>
          )}
        </div>
      </div>
    );
  }
}
