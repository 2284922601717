import { REHYDRATE } from 'redux-persist/lib/constants';
import createReducer from 'shared/utils/createReducer';
import { push } from 'connected-react-router';
import _isEqual from 'lodash.isequal';
import _get from 'lodash.get';
import _merge from 'lodash.merge';

const initialState = {
  notifications: [],
  acknowledging: []
};

const GET_PUSH_NOTIFICATION_TYPES = 'GET_PUSH_NOTIFICATION_TYPES';
const GET_PUSH_NOTIFICATION_TYPES_SUCCESS = 'GET_PUSH_NOTIFICATION_TYPES_SUCCESS';
const GET_PUSH_NOTIFICATION_TYPES_FAIL = 'GET_PUSH_NOTIFICATION_TYPES_FAIL';

const UPDATE_PUSH_NOTIFICATION_TYPES = 'UPDATE_PUSH_NOTIFICATION_TYPES';
const UPDATE_PUSH_NOTIFICATION_TYPES_SUCCESS = 'UPDATE_PUSH_NOTIFICATION_TYPES_SUCCESS';
const UPDATE_PUSH_NOTIFICATION_TYPES_FAIL = 'UPDATE_PUSH_NOTIFICATION_TYPES_FAIL';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

const ACKNOWLEDGE_NOTIFICATION = 'ACKNOWLEDGE_NOTIFICATION';
const ACKNOWLEDGE_NOTIFICATION_SUCCESS = 'ACKNOWLEDGE_NOTIFICATION_SUCCESS';
const ACKNOWLEDGE_NOTIFICATION_FAIL = 'ACKNOWLEDGE_NOTIFICATION_FAIL';

const UNACKNOWLEDGE_NOTIFICATION = 'UNACKNOWLEDGE_NOTIFICATION';
const UNACKNOWLEDGE_NOTIFICATION_SUCCESS = 'UNACKNOWLEDGE_NOTIFICATION_SUCCESS';
const UNACKNOWLEDGE_NOTIFICATION_FAIL = 'UNACKNOWLEDGE_NOTIFICATION_FAIL';

const ACKNOWLEDGE_ALL_NOTIFICATIONS = 'ACKNOWLEDGE_ALL_NOTIFICATIONS';
const ACKNOWLEDGE_ALL_NOTIFICATIONS_SUCCESS = 'ACKNOWLEDGE_ALL_NOTIFICATIONS_SUCCESS';
const ACKNOWLEDGE_ALL_NOTIFICATIONS_FAIL = 'ACKNOWLEDGE_ALL_NOTIFICATIONS_FAIL';

export default createReducer(initialState, {
  [REHYDRATE]: (state, action) => {
    return action.payload && action.payload.notifications
      ? {
          ...state,
          ...action.payload.notifications,
          acknowledging: [],
          push_notification_types: [],
          push_notification_loading: false,
          push_notification_error: null
        }
      : {
          ...state
        };
  },
  [ACKNOWLEDGE_NOTIFICATION]: (state, action) => ({
    ...state,
    acknowledging: [...state.acknowledging, action.notificationId]
  }),
  [ACKNOWLEDGE_NOTIFICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      notifications: [
        ...state.notifications.map(notif =>
          notif.id === action.notificationId ? { ...notif, acknowledged: true } : notif
        )
      ],
      acknowledging: state.acknowledging.filter(id => id !== action.notificationId)
    };
  },
  [ACKNOWLEDGE_NOTIFICATION_FAIL]: (state, action) => ({
    ...state,
    acknowledging: state.acknowledging.filter(id => id !== action.notificationId)
  }),
  [UNACKNOWLEDGE_NOTIFICATION]: (state, action) => ({
    ...state,
    acknowledging: [...state.acknowledging, action.notificationId]
  }),
  [UNACKNOWLEDGE_NOTIFICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      notifications: [
        ...state.notifications.map(notif =>
          notif.id === action.notificationId ? { ...notif, acknowledged: false } : notif
        )
      ],
      acknowledging: state.acknowledging.filter(id => id !== action.notificationId)
    };
  },
  [UNACKNOWLEDGE_NOTIFICATION_FAIL]: (state, action) => ({
    ...state,
    acknowledging: state.acknowledging.filter(id => id !== action.notificationId)
  }),

  [ACKNOWLEDGE_ALL_NOTIFICATIONS]: (state, action) => ({
    ...state,
    acknowledging: [...state.acknowledging, 'All']
  }),
  [ACKNOWLEDGE_ALL_NOTIFICATIONS_SUCCESS]: (state, action) => {
    return {
      ...state,
      notifications: [
        ...state.notifications.map(notif =>
          action.notificationIds?.includes(notif.id) ? { ...notif, acknowledged: true } : notif
        )
      ],
      acknowledging: state.acknowledging.filter(id => id !== 'All')
    };
  },
  [ACKNOWLEDGE_ALL_NOTIFICATIONS_FAIL]: (state, action) => ({
    ...state,
    acknowledging: state.acknowledging.filter(id => id !== 'All')
  }),

  [GET_NOTIFICATIONS]: (state, action) => ({ ...state }),
  [GET_NOTIFICATIONS_SUCCESS]: (state, action) => {
    return {
      ...state,
      notifications: action.result
    };
  },
  [GET_NOTIFICATIONS_FAIL]: (state, action) => ({ ...state }),
  [GET_PUSH_NOTIFICATION_TYPES]: (state, action) => { 
    return {
      ...state,
      push_notification_loading: true
    } 
  },
  [GET_PUSH_NOTIFICATION_TYPES_SUCCESS]: (state, action) => {
    return {
      ...state,
      push_notification_types: action.result,
      push_notification_loading: false
    };
  },
  [GET_PUSH_NOTIFICATION_TYPES_FAIL]: (state, action) => { 
    return {
      ...state,
      push_notification_loading: false
    }
  },
  [UPDATE_PUSH_NOTIFICATION_TYPES]: (state, action) => { 
    return{
      ...state,
      push_notification_loading: true,
      push_notification_error: null
    } 
  },
  [UPDATE_PUSH_NOTIFICATION_TYPES_SUCCESS]: (state, action) => {
    return {
      ...state,
      push_notification_types: action.result,
      push_notification_loading: false
    };
  },
  [UPDATE_PUSH_NOTIFICATION_TYPES_FAIL]: (state, action) => { 
    return{
      ...state,
      push_notification_loading: false,
      push_notification_error: "Error updating push notifications"
    } 
  },
});


export function updatePushNotificationTypes(data= []) {
  return {
    types: [
      UPDATE_PUSH_NOTIFICATION_TYPES,
      UPDATE_PUSH_NOTIFICATION_TYPES_SUCCESS,
      UPDATE_PUSH_NOTIFICATION_TYPES_FAIL
    ],
    promise: client =>
      client.put(`push-notification-types`, {
        segment: '/notifications/api',
        data: data
      })
  };
}

export function getPushNotificationTypes() {
  return {
    types: [
      GET_PUSH_NOTIFICATION_TYPES,
      GET_PUSH_NOTIFICATION_TYPES_SUCCESS,
      GET_PUSH_NOTIFICATION_TYPES_FAIL
    ],
    promise: client =>
      client.get(`push-notification-types`, {
        segment: '/notifications/api'
      })
  };
}

export function getNotifications() {
  return {
    types: [GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL],
    promise: client =>
      client.get(`notification`, {
        segment: '/notifications/api'
      })
  };
}

export function acknowledgeNotification(notificationId) {
  return {
    types: [
      ACKNOWLEDGE_NOTIFICATION,
      ACKNOWLEDGE_NOTIFICATION_SUCCESS,
      ACKNOWLEDGE_NOTIFICATION_FAIL
    ],
    notificationId,
    promise: client =>
      client.put(`notification/${notificationId}/acknowledge`, {
        segment: '/notifications/api'
      })
  };
}

export function unacknowledgeNotification(notificationId) {
  return {
    types: [
      UNACKNOWLEDGE_NOTIFICATION,
      UNACKNOWLEDGE_NOTIFICATION_SUCCESS,
      UNACKNOWLEDGE_NOTIFICATION_FAIL
    ],
    notificationId,
    promise: client =>
      client.put(`notification/${notificationId}/unacknowledge`, {
        segment: '/notifications/api'
      })
  };
}

export function acknowledgeAllNotifications(notifications) {
  const notificationIds = notifications.map(val => val.id);
  return {
    types: [
      ACKNOWLEDGE_ALL_NOTIFICATIONS,
      ACKNOWLEDGE_ALL_NOTIFICATIONS_SUCCESS,
      ACKNOWLEDGE_ALL_NOTIFICATIONS_FAIL
    ],
    notificationIds,
    promise: client =>
      client.put(`notification/acknowledge_list`, {
        segment: '/notifications/api',
        data: { notificantionIds: notificationIds }
      })
  };
}

export function gotoRoute(path) {
  return dispatch => dispatch(push(path));
}
