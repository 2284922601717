// Not sure where we will land with domains, subdomains, or just path driven
const base = '/mybv360/app';
const rxBase = '/mybv360/app/exogen-rx';
import { push } from 'connected-react-router';

export const buildUrl = (path, rx = false) =>
  path ? `${rx ? rxBase : base}${path}` : rx ? rxBase : base;
export const buildRxUrl = path => (path ? `${rxBase}${path}` : rxBase);

export const pushUrl = (path, rx = false) => (rx ? push(buildRxUrl(path)) : push(buildUrl(path)));

export const pushRawUrl = url => push(url);

export const urlStitch = (url, keyValues) => {
  let newUrl = url;
  Object.keys(keyValues).forEach(item => {
    newUrl = newUrl.replace(`:${item}`, keyValues[item]);
  });

  return newUrl;
};
// make a public url for login and registration
// read a little more about the private route decorator

export const URLS = {
  SSO: buildUrl('/sso'),
  LOGIN: buildUrl('/login'),
  LAYOUT: buildUrl('/layout'),
  CMN: buildRxUrl('/order/:orderId/:orderLocation/documents/cmn'),
  ORDER: buildRxUrl('/order/:orderId/:orderLocation'),
  COREVIST_DOWN: buildUrl('/outage/corevist'),
  SETUP_NOTICE: buildUrl('/user-setup-notice'),
  REGISTRATION: buildUrl('/registration'),
  REGISTRATION_SUCCESS: buildUrl('/registration/success'),
  ADMIN_CONTACTED: buildUrl('/registration/admin-contacted'),
  REQUEST_SAMPLE: buildUrl('/request-sample'),
  ACCOUNT_PROFILE: buildUrl('/account-profile'),
  UPDATE_SUCCESS: buildUrl('/update-success'),
  PRACTICE_USER_MANAGEMENT: buildUrl('/practice-user-management'),
  PRACTICE_DETAIL_MANAGEMENT: buildUrl('/practice-details'),
  PRACTICE_ADMINISTRATION: buildUrl('/practice-administration'),
  DOCUMENT_LIBRARY: buildUrl(
    '/document-library/:docType(exogen|durolane|gelsyn|supartz|ha-portfolio)'
  ),
  QUICK_SIGN_STANDALONE: buildRxUrl('/quick-sign-standalone/:docType'),
  QUICK_SIGN: buildRxUrl('/quick-sign/:uuid'),
  QUICK_SIGN_LOGGED_IN: buildRxUrl('/my-quick-sign/:uuid'),

  QUICK_SIGN_SUCCESS: buildRxUrl('/cmn_success'),

  QUICK_SIGN_DOWNLOAD_SUCCESS: buildRxUrl('/cmn_download_success'),
  QUICK_SIGN_ESIGN_SUCCESS: buildRxUrl('/cmn_esign_success'),

  SESSION_NOTICE: buildUrl('/session-notice'),
  EXOGEN_DASH: buildUrl('/exogen_dash'),

  BASE: buildUrl(),
  RX_BASE: buildRxUrl(),
  CONFIRM_PRACTICE_USER: buildUrl('/confirm-practice-user/:userEmail'),
};

export const URL_WHITELIST = [
  /\/sso/,
  /\/login/,
  /\/sso/,
  /\/registration/,
  /\/registration\/success/,
  /\/quick-sign/
];

export const urlRequiresLogin = url => !URL_WHITELIST.some(reg => url.match(reg));
