
const policyText =
     `  <strong>
          Contents<br />
        </strong>
        <br />
        <a href="#introduction">Introduction</a>
        <br />
        <a href="#how we collect">How We Collect Personal Information</a>
        <br />
        <a href="#how we use">How We Use Personal Information</a>
        <br />
        <a href="#types of personal info">Types of Personal Information Bioventus Collects</a>
        <br />
        <a href="#how we share personal info">How We Share Personal Information</a>
        <br />
        <a href="#links to external sites">Links to External Sites</a>
        <br />
        <a href="#cookies">Cookies</a>
        <br />
        <a href="#google analytics">Google Analytics</a>
        <br />
        <a href="#recaptcha">reCAPTCHA</a>
        <br />
        <a href="#clinical study">Clinical Study Participants</a>
        <br />
        <a href="#job applicants">Job Applicants</a>
        <br />
        <a href="#legal basis">Legal Basis for Processing</a>
        <br />
        <a href="#purpose limitation">Purpose Limitation</a>
        <br />
        <a href="#data security">Data Security</a>
        <br />
        <a href="#data retention">Data Retention</a>
        <br />
        <a href="#data transfers">Data Transfers</a>
        <br />
        <a href="#children privacy">Privacy Statement for Children</a>
        <br />
        <a href="#your rights">Your Rights</a>
        <br />
        <a href="#california rights">California Resident Rights to Restrict Sharing or Selling Data</a>
        <br />
        <a href="#marketing choices">Your Marketing Choices</a>
        <br />
        <a href="#privacy notice updates">Privacy Notice Changes and Updates</a>
        <br />
        <a href="#contact info">Contact Information</a>


        <br /><br />

        <div id="introduction">
        <strong>
          Introduction <br />
        </strong></div>
        <p>
        This privacy policy explains how Bioventus and its subsidiaries and affiliates, including Bioness, CartiHeal and Misonix
          (collectively “Bioventus,” “we,” “us,” “our”) collects, uses, shares, and protects information gathered through
          the Bioventus websites (the “Websites”) or other information gathering platforms Bioventus may use (collectively,
          “Bioventus Platforms”), as well as through our offline interactions with you.
          Bioventus is the controller, as defined by the European General Data Protection Regulation, for all data
          collected through Bioventus Platforms.
          In some cases, Bioventus may issue more specific privacy policies for some products or services.
        </p>
        <p>
          If you do not want your information handled as described in this privacy policy, please do not provide us
          with your information or interact with the Bioventus Platforms.
        </p>

        <div id="how we collect">
        <strong>
          How We Collect Personal Information <br />
        </strong></div>
        <p>
          Bioventus may collect personal information about you from a variety of sources, including:
          <ul>
            <li><u>From you</u>: Information may be collected when you submit it to the Bioventus Platforms or otherwise
            interact with us. For example, Bioventus collects the information you provide when you make a purchase,
            when you submit a request for information, or submit a request for Bioventus to contact you.
            Bioventus may also request optional information from you to support your use of the products and services.
            </li>
            <li><u>From your devices</u>: The devices you use to access the Bioventus Platforms may provide information to us,
            including the model, operating system and version, the name of the domain from which you access the Internet,
            your Internet Protocol (“IP”) address, and other unique device identifiers. Bioventus also collects device
            information like the date and time you access the Bioventus Platforms, which pages or portions of the
            Bioventus Platforms you visit, the search terms you use, the links you click on, the browser you use, and
            your language preference. To collect much of this information, we use cookies, web beacons and other similar
            technologies. Please see the section below titled “Cookies” for more information.
            </li>
            <li><u>From our mobile apps</u>: All data collected and stored via our Apple and Android apps is stored
              exclusively on your mobile device and is never transmitted to Bioventus. When you meet with your healthcare
              provider, he or she may access the data collected through our mobile apps via a specialized tablet that will
              connect to your mobile device via a Bluetooth short-range communication signal.
            </li>
            <li><u>From third parties</u>: Our vendors and service providers, such as our web hosting providers,
            analytics providers, and advertisers, may provide us information about you or your use of the Websites or
            Bioventus Platforms. You may also give us permission to access your information from services offered by
            third parties, including social media networks. The information we obtain from third parties depends on your
            relationship with those third parties and the third parties’ privacy policies.
            </li>
            <li><u>From your healthcare provider</u>: With your authorization, your healthcare provider may send us
            prescriptions for our products. Your healthcare provider may send information related to your current
            health status and medical history relevant to the prescription or to filing for health insurance coverage
            for the prescription.
            </li>
          </ul>
        </p>

        <div id="how we use">
        <strong>
          How We Use Personal Information<br />
         </strong></div>
        <p>
          Bioventus may use the personal information we collect:
          <ul>
            <li>
              To fulfill or meet the reason you provided the information. For example, if you share your name and
              contact information to ask a question, we will use that personal information to respond to your inquiry.
            </li>
            <li>
              To provide health insurance reimbursement services to you.
            </li>
            <li>
              To enter into and fulfill business agreements with you.
            </li>
            <li>
              To complete online or offline transactions with you.
            </li>
            <li>
              To understand your experience with our products.
            </li>
            <li>
              To provide and personalize your access and use of the Websites, Bioventus Platforms, and Bioventus products and services.
            </li>
            <li>
              To provide you with educational information, marketing, communications, and offers for products and services
              from Bioventus and third parties.
            </li>
            <li>
              To plan and coordinate events.
            </li>
            <li>
              To protect someone’s health, security, or welfare.
            </li>
            <li>
              To process job applications.
            </li>
            <li>
              To conduct clinical research.
            </li>
            <li>
              To monitor the safety and efficacy of Bioventus products.
            </li>
            <li>
              For research, analysis, and product development, including to develop and improve our Websites, Bioventus
              Platforms, and products.
            </li>
            <li>
              To engage in charitable activities.
            </li>
            <li>
              To manage investor relations and Bioventus securities.
            </li>
            <li>
              To operate and maintain the Websites and Bioventus Platforms.
            </li>
            <li>
              To maintain the rights, safety, and security of Bioventus, the Websites, Bioventus Platforms, products,
              databases, and other technology assets.
            </li>
            <li>
              To respond to law enforcement requests and as required by applicable law, court order, or governmental
              regulations, including our transparency reporting obligations.
            </li>
            <li>
              To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale
              or transfer of some or all of our assets, whether as a going concern or as part of a bankruptcy, liquidation,
              or similar proceeding, in which personal information held by us is among the assets transferred.
            </li>
            <li>
              As otherwise described to you at the time your personal information is collected, or with your consent,
              if not listed here.
            </li>
            <li>
              As otherwise required by law.
            </li>
          </ul>
        </p>

        <div id="types of personal info">
          <strong>
          Types of Personal Information Bioventus Collects<br />
         </strong></div>
        <p>
        Bioventus may collect the following categories of personal information from and about you depending on the
          circumstances of your relationship with us:
        <ul>
          <li><u>Identifiers</u> such as your name.</li>
          <li><u>Commercial information</u> such as payment or billing information and information related to your purchasing habits.</li>
          <li><u>Contract information</u> such as postal addresses, email addresses, social networking website user account names, telephone numbers,
            or other addresses at which you are able to receive communications or shipments.</li>
          <li><u>Internet or other similar network and device activity</u> such as information about your browsing behavior,
            app activity, IP addresses, search history and interactions with our websites, emails, and advertisements,
            including data from cookies, pixel tags, and web beacons.</li>
          <li><u>Inferences regarding preferences and other characteristics</u> such as our assessment of the types of
            products or services you may have an interest in.</li>
          <li><u>Audio, video, and pictures</u> such as recordings you may be included in when participating in our events
            or interacting with us.</li>
          <li><u>Training and event</u> attendance records.</li>
          <li><u>Professional or employment-related information</u>, if you are a job applicant, such as your current or past education and job history.</li>
          <li><u>Health Data and Health Insurance Information</u>, if you are a patient who placed an order for one of our products
            or are involved in a clinical trial. This may include information such as your complete medical history,
            current medical status, health insurance carrier, health insurance plan information, Medicare information,
            medical IDs, and government identifiers (e.g., Social Security number). </li>
        </ul></p>

        <div id="how we share personal info">
        <strong>
          How We Share Personal Information<br />
         </strong></div>
        <p>Bioventus may share your personal information for the following purposes:
          <ul>
            <li>
             With your healthcare provider when required to complete an order or provide updates on medical device usage.
            </li>
            <li>With private and public health insurance providers, such as Medicare, to process reimbursements for
              services and products provided.
            </li>
            <li>With service providers who perform a variety of services and functions for Bioventus, such as data storage,
              order fulfillment, and marketing services. Your information will only be shared on the condition that the
              service providers use your information only to fulfill the agreed services on our behalf.
            </li>
            <li>With researchers and academics who may use your information in accordance with this privacy policy.
            </li>
            <li>In the event we go through a business transition such as a merger, acquisition by another company,
              bankruptcy, reorganization, or sale of all or a portion of Bioventus’ assets.
            </li>
            <li>With our online advertising partners, such as Facebook and Google, for the purpose of advertising to you.
            </li>
            <li>With law enforcement or governmental agencies to comply with any court order, law, or legal process,
              including to respond to any government or regulatory request.
            </li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
              Bioventus, our customers, or others. This includes exchanging information with other companies and organizations
              for the purposes of fraud protection and credit risk reduction.
            </li>
          </ul></p>
          <p>
          Bioventus may also reveal your personal information to unrelated third parties for their independent use if:
            (1) you request or authorize it; (2) the information is provided to comply with the law; or (3) the information
            is provided to protect Bioventus’ rights or safety, or the rights or safety of Bioventus employees or others.
        </p>

        <div id="links to external sites">
          <strong>
          Links to External Websites <br />
       </strong></div>
      <p>
      The Websites and Bioventus Platforms may contain links to external websites. The Websites interface with social
        media sites such as Instagram, Facebook, LinkedIn, Twitter, YouTube, and others. A link to such sites does not
        imply endorsement by Bioventus of such sites. This privacy policy does not apply to those external websites, and
        you should review the privacy policies of these external sites.
      </p>

      <div id="cookies">
        <strong>
          Cookies <br />
       </strong></div>
      <p>
        Cookies are text files stored on the Websites’ server and your web browser and are used by the Websites to remember
        you for various purposes. To learn more about the cookies used on our Websites, or to change or withdraw your
        consent at any time, please click the Cookie Settings link in the cookie notice banner or in the footer of our Websites.
      </p>
      <p>
        In order to provide your consent for non-essential cookies, please click on the button on the pop-up cookie banner
        message or adjust your preferences in Cookie Settings. Some cookies are essential for the functioning of the
        Websites and may not be deactivated using the Cookie Settings controls.
      </p>
      <p>You may also alter your browser settings to block cookies. Browsers are different, so refer to instructions
        related to your browser to learn about cookie-related and other privacy and security settings that may be available.
        For more information on cookies and how to disable them, you can consult the information provided by visiting the
        Networking Advertising Initiative site at <a href="https://optout.networkadvertising.org/">https://optout.networkadvertising.org/</a>
        and the Digital Advertising Alliance site at <a href="https://youradchoices.com/">https://youradchoices.com/</a>.</p>


        <div id="google analytics">
          <strong>
          Google Analytics <br />
          </strong></div>
      <p>
        We use Google Analytics to understand how users interact with our Websites and to improve how our business communicates
        its commercial message online. Google Analytics is a service provided by Google (Google LLC, 1600 Amphitheatre Parkway,
        Mountain View, CA 94043). Google Analytics uses cookies, which transmit anonymized or truncated personal data to
        a Google server. The information about our Websites generated by a Google Analytics cookie helps us evaluate the
        use of the Websites, compile reports on the Websites activity, and provide us with other services relating to
        website activity and internet usage.
      </p>
      <p>
        You can find more information about Google’s data protection practices and terms of use by visiting Google’s
        websites at <a href="https://www.google.com/analytics/terms/us.html">https://www.google.com/analytics/terms/us.html</a>
        and <a href="https://policies.google.com/privacy">https://policies.google.com/privacy.</a>
      </p>
      <p>
        You can prevent Google from collecting and processing cookie-generated data relating to your use of a website
        by downloading and installing the browser plug-in available at the following link: <a href="http://tools.google.com/dlpage/gaoptout">
        http://tools.google.com/dlpage/gaoptout.</a>
      </p>

        <div id="recaptcha">
          <strong>
          reCAPTCHA <br />
       </strong></div>
      <p>
        We use the "reCAPTCHA" service from Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043) to
        protect the information that you provide on our Website. With this service, we can differentiate between information
        entered into a form by a human and information entered by an automated machine (“bot”).
      </p>
      <p>
        The information obtained via the reCAPTCHA service is used in accordance with Google''s Usage Terms and Conditions:
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy.</a>
      </p>

       <div id="clinical study">  <strong>
          Clinical Study Participants <br />
       </strong></div>
      <p>
        If you participate in a Bioventus-sponsored clinical trial, you will receive detailed information on the scope
        of personal data processing in your informed consent document. The information you are given for your specific
        trial controls your situation. In general, your data will be processed for the purpose of conducting the trial,
        applying for the approval of a new medical device product, and, if applicable, for other purposes as specified
        in the pertinent declaration of consent for the study. Anyone who views your identified personal data during a
        clinical trial is obligated to hold this information in strict confidence.
      </p>

     <div id="job applicants"><strong>
          Job Applicants <br />
       </strong></div>
      <p>
        If you apply for a job with Bioventus, we will use the personal data that you provide in your application,
        including any attachments sent, for the purpose of considering your candidacy for employment. This data includes
        your name and contact information, the information in your CV or resume, your job preferences, and your profile.
        If you have a social media profile, including a LinkedIn profile, Bioventus may process the data you choose to share
        on social media as well.
      </p>

       <div id="legal basis"><strong>
          Legal Basis for Processing <br />
       </strong></div>
      <p>
        Bioventus will only process your personal data when allowed by law; that is, when we have a legal basis for processing.
        We use personal data when one of the following legal bases applies:
        <ul>
          <li><u>Performance of a Contract</u>: Where necessary to perform a contract with you or to take steps at your
            request before entering into such a contract.
          </li>
          <li><u>Legal or Regulatory Obligation</u>: Where necessary for compliance with a legal or regulatory obligation that we are subject to.
          </li>
          <li><u>Legitimate Interests</u>: Where necessary for our interests, provided that your fundamental rights do not
            override such interests. We consider and balance the potential impact on you and your rights (both positive
            and negative) before we process your personal data for our legitimate interests.
          </li>
          <li><u>Public Interest</u>: Where the processing is necessary for ensuring high standards of quality and safety
            of healthcare and medical devices.
          </li>
          <li><u>Consent</u>: Where you have provided your consent for processing your personal data.
          </li>
        </ul>
        Where Bioventus relies on your consent as the legal basis for processing, you have the right to withdraw your consent at any time.
      </p>

    <div id="purpose limitation"><strong>
      Purpose Limitation <br />
       </strong></div>
      <p>
        Bioventus will only process personal information in a way that is compatible with and relevant to the purpose for
        which it was collected or authorized by you, or as we notify you if the purposes change. You have the right to
        object to or request that we restrict the processing of your personal data for such additional purposes.
      </p>

    <div id="data security"><strong>
      Data Security <br />
       </strong></div>
      <p>
        Bioventus will take reasonable steps to ensure that personal information is accurate, complete, current, secure,
        and reliable for its intended uses. We employ procedural and technological security measures that are designed
        to protect your personal information from loss, unauthorized access, disclosure, alteration, or destruction.
      </p>

      <div id="data retention"><strong>
      Data Retention <br />
       </strong></div>
      <p>
        Bioventus will retain your personal information for as long as reasonably necessary for legitimate business
        purposes or as required by law. Personal information will be kept until it is no longer necessary to provide our
        products and services, to fulfill our contractual agreements with you, to comply with applicable law, to protect
        Bioventus’ rights and interests (e.g., where the retention is necessary for the establishment, exercise, or
        defense of legal claims), or as otherwise needed for the purposes for which it was collected or processed.
      </p>

      <div id="data transfers"><strong>
      Data Transfers <br />
       </strong></div>
      <p>
        At times, personal data may be transferred to service providers or systems in countries that may not offer a
        level of data protection equivalent to that in your country, including the United States. Where such transfers
        occur, Bioventus complies with the cross-border data transfer and export control laws of the countries in which
        it operates.
      </p>
      <p>
      For personal data exports out of the European Union (“EU”) or European Economic Area (“EEA”), Bioventus enters
        into the European Commission’s Standard Contractual Clauses with the recipient to ensure compliance with the
        special requirements on transfers of personal data out of such countries. Where necessary, Bioventus will take
        appropriate supplementary measures to ensure an essentially equivalent level of data protection to that guaranteed
        in the EEA, in accordance with European Data Protection Board ("EDPB") recommendations.
      </p>

      <div id="children privacy"><strong>
      Privacy Statement for Children <br />
       </strong></div>
      <p>
        The Websites and Bioventus Platforms are not intended to be directed to children that are younger than thirteen.
        Bioventus does not knowingly collect or intend to collect personal information from children that are younger
        than thirteen. If you believe we might have any information from or about a child under thirteen,
        please contact us at <a href="mailto:privacy@bioventus.com">privacy@bioventus.com.</a>
      </p>

      <div id="your rights"><strong>
      Your Rights <br />
       </strong></div>
      <p>
        Some privacy laws, including the EU General Data Protection Regulation (“GDPR”) and California Consumer Privacy
        Act (“CCPA”), provide certain rights for individuals. You may have the following rights, in accordance with
        applicable laws and subject to necessary validation of your identity:
        <ul>
          <li>
            Request to access or obtain a copy of your personal data being processed.
          </li>
          <li>
            Request correction of inaccurate personal data relating to you.
          </li>
          <li>
            Object to Bioventus’ processing of your personal data.
          </li>
          <li>
            Oppose processing based on our legitimate interest for reasons relating to your particular situation.
            Please note that we may continue to process your personal data, even if you have opposed the processing,
            if we have compelling legitimate grounds for the processing which overrides your privacy interests.
          </li>
          <li>
            Request, under certain circumstances, the deletion or restriction of the processing of your personal data.
          </li>
          <li>
            Lodge a complaint with a Data Protection Authority if you have concerns about our practices regarding the processing of personal data.
          </li>
        </ul>
        To exercise these rights, please send an email to <a href="mailto:privacy@bioventus.com">privacy@bioventus.com</a>
        with the details of your request.
      </p>

      <div id="california rights"><strong>
      California Resident Rights to Restrict Sharing or Selling Data <br />
       </strong></div>
      <p>
        Bioventus uses third-party cookies and tracking technologies, such as Facebook Pixels, to identify and place
        advertisements targeted to users who visit Bioventus Platforms. The California Consumer Privacy Act (“CCPA”)
        defines the use of such cookies and tracking technologies as “selling” and “sharing” personal data.
      </p>
      <p>
       California residents may opt-out of the selling or sharing of their personal data by calling 1-800-836-4080,
        emailing <a href="mailto:privacy@bioventus.com">privacy@bioventus.com</a>, or clicking the link in the cookie manager.
      </p>

      <div id="marketing choices"><strong>
      Your Marketing Choices <br />
       </strong></div>
      <p>You can limit the information you provide to Bioventus, and you can limit the communications that Bioventus sends to you.
        <ul>
          <li><u>Marketing Emails</u>: You may choose not to receive marketing emails from us by following the instructions
            contained in any of the marketing emails we send. Please note that even if you unsubscribe from marketing
            email messages, we may still email you non-marketing emails.
          </li>
          <li><u>Cookies</u>: Please refer to the “Cookies” section above for information about your choices in regard
            to the cookies on the Websites.
          </li>
          <li><u>Do Not Track</u>: Currently, Bioventus does not respond to Do Not Track (“DNT”) signals. All About Do Not Track,
            a Future of Privacy Forum website, has more information about DNT signals and is located at:
            <a href="https://allaboutdnt.com/">https://allaboutdnt.com/.</a>
            </li>
        </ul>
      </p>

      <div id="privacy notice updates"><strong>
      Privacy Notice Changes and Updates <br />
       </strong></div>
      <p>
        This privacy notice may be amended at any time. If material changes are made in how personal information is collected,
        used, disclosed, or otherwise processed, this privacy policy will be updated, including the “Effective Date” at
        the bottom of this privacy policy. We recommend that you review the privacy policy each time you visit the Website
        or use Bioventus Platforms to stay informed of our privacy practices. Any changes will be effective when we post
        the updated privacy policy.
      </p>

        <div id="contact info"><strong>
          Contact Information <br />
         </strong></div>
        <p>
          All questions, complaints, or comments about this privacy policy or Bioventus’ data protection practices can
          be emailed to Bioventus’ Data Protection Officer at
          <a href="mailto:privacy@bioventusglobal.com">privacy@bioventusglobal.com</a>.
          We will use reasonable efforts to respond to you as soon as possible. You may also contact us using the information below:
        </p>

        <p>
          Bioventus LLC <br />
          ATTN: Compliance Department <br />
          4721 Emperor Blvd., Suite 400 <br />
          Durham, NC 27703 <br />
          1-800-836-4080 <br />
        </p>
        <p><i>
          Effective Date: December 29, 2022
          </i>
        </p>
`;

export { policyText };
