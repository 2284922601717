const isEqual = require('lodash.isequal');
const setToJSON = require('set-tojson');
const Engine = require('json-rules-engine').Engine;
const Facts = require('./facts');
const {
  comorbiditySubset,
  fieldExists,
  getByKeyField,
  daysSinceSurgery,
  daysSinceInjury,
  xraySinceInjury,
  xraySinceRx,
  timeBetweenXrays,
  medicareIcd
} = Facts;

const engineConfig = {
  allowUndefinedFacts: true,
  prioritizedRules: true
};

setToJSON.shim();

const isClient = typeof window !== 'undefined' && window.document;

const stripRules = rules => rules.reduce((acc, rule) => [...acc, rule.rule], []);
const filterByPayerId = (rules, payerId, groupId) => {
  if (!payerId && !groupId) {
    return rules.filter(({ payer, group }) => !payer && !group);
  }

  if (payerId && !groupId) {
    return rules.filter(({ payer, group }) => (!payer && !group) || payer === payerId);
  }

  if (payerId && groupId) {
    return rules.filter(
      ({ payer, group }) => (!payer && !group) || payer === payerId || group === groupId
    );
  }
};

let _engine = null;
let _rules = null;
let _payerId = null;
let _groupId = null;

module.exports = {
  init: (rules, payerId, groupId, ignoreCache) => {
    if (!rules || rules.length === 0) {
      return;
    }

    if (!ignoreCache && _engine && payerId === _payerId && isEqual(rules, _rules)) {
      return;
    }

    _rules = rules;
    _payerId = payerId;
    _groupId = groupId;

    const baseRules = filterByPayerId(rules, payerId, groupId);
    const pureRules = stripRules(baseRules);

    if (isClient) {
      const ruleTable = baseRules.map(rule => {
        return {
          id: rule.id,
          name: rule.rule_name,
          priority: rule.priority,
          effectiveDate: rule.effective_date,
          updatedDate: rule.last_changed_date
        };
      });

      console.log(ruleTable);
    }

    const engine = new Engine(pureRules, engineConfig);

    engine.addFact('getByKeyField', getByKeyField);
    engine.addFact('fieldExists', fieldExists);
    engine.addFact('days_since_surgery', daysSinceSurgery);
    engine.addFact('days_since_injury', daysSinceInjury);
    engine.addFact('xray_since_injury', xraySinceInjury);
    engine.addFact('comorbiditySubset', comorbiditySubset);
    engine.addFact('time_between_xrays', timeBetweenXrays);
    engine.addFact('xray_since_rx', xraySinceRx);
    engine.addFact('medicare_covered', medicareIcd);
    _engine = engine;
  },
  isInitialized: () => !!_engine,
  evaluateOrder: order => {
    const draftOrOrder = order && order.order ? order.order : order;

    if (!_engine) throw new Error('Rules Engine Not Initialized');

    return _engine.run(draftOrOrder).then(result => {
      if (!result) {
        return [];
      }

      const reversedResult = [];
      for (let i = result.length - 1; i >= 0; i--) {
        reversedResult.push(result[i]);
      }

      // Rules are prioritized.  We take the highest priority rule when
      // There are multiple events with the same type.
      const uniqueKeys = new Set(result.map(event => event.type)).toJSON();
      const prioritizedResults = [];
      uniqueKeys.forEach(key =>
        prioritizedResults.push(reversedResult.find(event => event.type === key))
      );



      return prioritizedResults;
    });
  }
};
