import createReducer from 'shared/utils/createReducer';
import { REHYDRATE } from 'redux-persist/lib/constants';

import {
  USER_GET_FAIL,
  USER_GET_SUCCESS,
  USER_LOGIN_NEEDED,
  USER_LOGOUT,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_SUCCESS,
  USER_SSO_CHECK,
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAIL,
  USER_REGISTRATION_MODAL_TOGGLE,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_GET_PRACTICES,
  USER_GET_PRACTICES_SUCCESS,
  USER_GET_PRACTICES_FAIL,
  DEACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
  GET_PRACTICE_USERS,
  GET_PRACTICE_USERS_SUCCESS,
  GET_PRACTICE_USERS_FAIL,
  EDIT_PRACTICE_USER,
  EDIT_PRACTICE_USER_SUCCESS,
  EDIT_PRACTICE_USER_FAIL,
  ADD_PRACTICE_USER,
  ADD_PRACTICE_USER_SUCCESS,
  ADD_PRACTICE_USER_FAIL,
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAIL,
  SEARCH_PRACTICES,
  SEARCH_PRACTICES_SUCCESS,
  SEARCH_PRACTICES_FAIL,
  START_DEMO,
  START_DEMO_SUCCESS,
  START_DEMO_FAIL,
  STOP_DEMO,
  STOP_DEMO_SUCCESS,
  STOP_DEMO_FAIL,
  USER_ADD_MODAL_TOGGLE,
  USER_NEW,
  GET_ORDER_DATA,
  GET_ORDER_DATA_SUCCESS,
  GET_ORDER_DATA_FAIL,
  GET_USER_COPILOT_DETAILS,
  GET_USER_COPILOT_DETAILS_SUCCESS,
  GET_USER_COPILOT_DETAILS_FAIL,
  CLEAR_COPILOT_DETAILS,
  CLEAR_PRACTICE_EDIT_ERRORS,
  GET_CONFIRM_USER_INFO,
  GET_CONFIRM_USER_INFO_SUCCESS,
  GET_CONFIRM_USER_INFO_FAIL,
  SET_CONFIRM_USER_INFO,
  SET_CONFIRM_USER_INFO_SUCCESS,
  SET_CONFIRM_USER_INFO_FAIL,
  TOGGLE_CONFIRM_USER_RESULT_MODAL
} from 'bv360/redux/actions/user';

const initialState = {
  addUserModalOpen: false,
  requireFax: false,
  user: null,
  ssoComplete: null,
  practice_edit_error: null,
  error: null,
  loading: false,
  loadingDemo: false,
  loadingCoPilot: false,
  officesWithUsers: [],
  practices: [],
  prefs: {},
  practice_users: [],
  admin_practice_names: [],
  copilot_user_details: {},
  copilot_user_details_error: null,
  showContactAdminModal: false,
  confirmUserLoading: false,
  userToBeConfirmed: [],
  confirmUserError: null,
  confirmUserResultModalOpen: false
};

export default createReducer(initialState, {
  [REHYDRATE]: (state, action) => {
    return action.payload && action.payload.user
      ? {
          ...state,
          user: action.payload.user.user,
          practices: action.payload.user.practices,
          practice_users: action.payload.user.practice_users,
          officesWithUsers: [],
          showContactAdminModal: false
        }
      : {
          ...state
        };
  },
  [TOGGLE_CONFIRM_USER_RESULT_MODAL]: (state, action) => {
    return {
      ...state,
      confirmUserResultModalOpen: action.open
    };
  },
  [GET_CONFIRM_USER_INFO]: state => {
    return {
      ...state,
      confirmUserLoading: true,
      confirmUserError: null,
      userToBeConfirmed: []
    };
  },
  [GET_CONFIRM_USER_INFO_SUCCESS]: (state, action) => {
    return {
      ...state,
      confirmUserLoading: false,
      userToBeConfirmed: action.userToBeConfirmed
    };
  },
  [GET_CONFIRM_USER_INFO_FAIL]: (state, action) => {
    return {
      ...state,
      confirmUserLoading: false,
      confirmUserError: 'Get confirm user error'
    };
  },
  [SET_CONFIRM_USER_INFO]: state => {
    return {
      ...state,
      confirmUserLoading: true,
      confirmUserError: null
    };
  },
  [SET_CONFIRM_USER_INFO_SUCCESS]: state => {
    return {
      ...state,
      confirmUserLoading: false
    };
  },
  [SET_CONFIRM_USER_INFO_FAIL]: (state, action) => {
    return {
      ...state,
      confirmUserLoading: false,
      confirmUserError: 'Set confirm user error'
    };
  },

  [CLEAR_PRACTICE_EDIT_ERRORS]: state => {
    return {
      ...state,
      practice_edit_error: null
    };
  },
  [CLEAR_COPILOT_DETAILS]: state => {
    return {
      ...state,
      copilot_user_details: {}
    };
  },
  [GET_USER_COPILOT_DETAILS]: state => {
    return {
      ...state,
      loadingCoPilot: true,
      copilot_user_details_error: null
    };
  },
  [GET_USER_COPILOT_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingCoPilot: false,
      copilot_user_details: action.user_details
    };
  },
  [GET_USER_COPILOT_DETAILS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingCoPilot: false,
      copilot_user_details_error: action.error
    };
  },
  [USER_LOGIN_NEEDED]: state => {
    return {
      ...state,
      user: null
    };
  },
  [USER_GET_SUCCESS]: (state, { user }) => {
    const isAdmin = user?.is_superuser || false;
    const isCustomerService = user?.groups?.includes('BV360 Customer Service') || false;

    return {
      ...state,
      user,
      isAdmin,
      isCustomerService,
      isAdminOrCS: isAdmin || isCustomerService
    };
  },
  [USER_GET_FAIL]: (state, action) => {
    return {
      ...state,
      user: null,
      ssoComplete: action.ssoComplete
    };
  },
  [USER_LOGOUT]: state => {
    return {
      ...state
    };
  },
  [USER_LOGOUT_SUCCESS]: state => {
    return {
      ...state
    };
  },
  [USER_LOGOUT_FAIL]: state => {
    return {
      ...state
    };
  },
  [USER_SSO_CHECK]: (state, action) => {
    return {
      ...state,
      ssoComplete: action.ssoComplete
    };
  },
  [USER_REGISTRATION]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [USER_REGISTRATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  },
  [USER_REGISTRATION_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      officesWithUsers: action.officesWithUsers,
      showContactAdminModal: true
    };
  },
  [USER_REGISTRATION_MODAL_TOGGLE]: state => ({
    ...state,
    showContactAdminModal: !state.showContactAdminModal
  }),
  [USER_UPDATE]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [USER_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      admin_practice_names: []
    };
  },
  [USER_UPDATE_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      practice_edit_error: action.practice_edit_error
    };
  },
  [USER_GET_PRACTICES]: (state, action) => {
    return {
      ...state,
      practices: [],
      error: null,
      loading: true
    };
  },
  [USER_GET_PRACTICES_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: action.hasFurtherAction ? true : false,
      practices: action.practices
    };
  },
  [USER_GET_PRACTICES_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },

  [DEACTIVATE_USER]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [DEACTIVATE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  },
  [DEACTIVATE_USER_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },
  [GET_PRACTICE_USERS]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [GET_PRACTICE_USERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      practice_users: action.compiled_users
    };
  },
  [GET_PRACTICE_USERS_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },
  [EDIT_PRACTICE_USER]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [EDIT_PRACTICE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      user_details: {}
    };
  },
  [EDIT_PRACTICE_USER_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },
  [ADD_PRACTICE_USER]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [ADD_PRACTICE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      addUserModalOpen: false
    };
  },
  [ADD_PRACTICE_USER_FAIL]: (state, { error, requireFax }) => {
    return {
      ...state,
      loading: false,
      error,
      requireFax
    };
  },
  [CONTACT_US]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [CONTACT_US_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  },
  [CONTACT_US_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },
  [SEARCH_PRACTICES]: (state, action) => {
    return {
      ...state,
      error: null,
      loading: true
    };
  },
  [SEARCH_PRACTICES_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      admin_practice_names: action.admin_practice_names
    };
  },
  [SEARCH_PRACTICES_FAIL]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },
  [START_DEMO]: state => {
    return {
      ...state,
      error: null,
      loadingDemo: true
    };
  },
  [START_DEMO_SUCCESS]: state => {
    return {
      ...state,
      loadingDemo: false
    };
  },
  [START_DEMO_FAIL]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loadingDemo: false
    };
  },
  [STOP_DEMO]: state => {
    return {
      ...state,
      error: null,
      loadingDemo: true
    };
  },
  [STOP_DEMO_SUCCESS]: state => {
    return {
      ...state,
      loadingDemo: false
    };
  },
  [STOP_DEMO_FAIL]: (state, action) => {
    return {
      ...state,
      error: action.error,
      loadingDemo: false
    };
  },
  [USER_ADD_MODAL_TOGGLE]: state => {
    return {
      ...state,
      requireFax: false,
      error: null,
      addUserModalOpen: !state.addUserModalOpen
    };
  },
  [USER_NEW]: state => {
    return {
      ...state,
      practices: [],
      practice_users: [],
      admin_practice_names: []
    };
  }
});
