import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import { SpinnerIcon } from 'shared/components/spinner/spinner';
import Spinner from 'shared/components/spinner/spinner';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useIsMobile from 'exogen/hooks/isMobile';
import { OrderState } from 'exogen/hooks/order';

const delay = { show: 250, hide: 400 };
const alignRight = { textAlign: 'right' };

const Panel = ({
  title,
  id = null,
  collapsible = false,
  // defaultOpen = false,
  children,
  titleExtra = '',
  hideHeader = false,
  loading = false,
  cardStyle = null,
  cardBodyStyle = null,
  headerSpinner = true,
  bodySpinner = false,
  headerStyle = null,

  faHeaderLoadingIcon = false,
  faHeaderIconExists = false,
  faHeaderIconHandler = null,

  headerButtonExists = false,
  headerButtonText = '',
  headerButtonStyle = {},
  headerButtonIcon = null,
  headerButtonHandler = null,
  headerButtonHandlerData = {},

  headerInfoHoverExists = false,
  headerInfoText = null,

  specialClassName = null,
  hoverPlacement = 'top',
  spinnerStyle = {},

  otherIcons = null,
  ...props
}) => {
  const isMobile = useIsMobile();
  const { isDraft } = OrderState();
  const [useCollapse, setUseCollapse] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!isDraft && isMobile && collapsible) {
      setUseCollapse(true);
    }
  }, [isDraft, isMobile, collapsible]);

  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      className="panel-tooltip"
      arrowProps={{ ref: 'panel-arrow' }}
      {...props}
    >
      {headerInfoText}
    </Tooltip>
  );

  const titleSection = title ? `${title} ${titleExtra}` : null;

  return (
    <Card
      id={id ? `${id}` : title ? title.toLowerCase() : null}
      className={'card-custom ' + specialClassName}
      style={cardStyle}
    >
      {hideHeader ? null : (
        <Card.Header style={headerStyle} onClick={() => (useCollapse ? setOpen(!open) : null)}>
          {useCollapse ? (
            <span>
              <FontAwesomeIcon icon={open ? 'angle-up' : 'angle-down'} /> {titleSection}
            </span>
          ) : (
            titleSection
          )}{' '}
          {faHeaderLoadingIcon
            ? loading && headerSpinner && <FontAwesomeIcon icon="sync" fixedWidth spin />
            : loading && headerSpinner && <SpinnerIcon />}
          {headerButtonExists && !loading && (
            <Button onClick={() => headerButtonHandler(headerButtonHandlerData)} style={headerButtonStyle}>
              {headerButtonIcon} {headerButtonText}
            </Button>
          )}
          {faHeaderIconExists && !loading && (
            <FontAwesomeIcon icon="sync" fixedWidth onClick={() => faHeaderIconHandler()} />
          )}
          {otherIcons &&
            otherIcons.map((icon, index) => {
              return (
                <FontAwesomeIcon
                  key={index}
                  icon={icon.icon}
                  fixedWidth
                  onClick={() => icon.handler()}
                  title={icon.title}
                />
              );
            })}
          {headerInfoHoverExists && (
            <OverlayTrigger placement={hoverPlacement} delay={delay} overlay={renderTooltip}>
              <FontAwesomeIcon icon="info-circle" style={alignRight} />
            </OverlayTrigger>
          )}
        </Card.Header>
      )}
      <Collapse in={useCollapse ? open : true}>
        <Card.Body style={cardBodyStyle}>
          {' '}
          {(bodySpinner && loading && <Spinner isStatic={true} extraStyle={spinnerStyle} />) ||
            children}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default Panel;
