import React, { useState } from 'react';
import superagent from 'superagent';
import { errorValues, inputClassNames } from 'shared/components/form/fields/utils';
import { debounce } from 'debounce';

class LoqateApi {
  constructor({ key, countries }) {
    this._key = key;
    this._countries = countries || [];
  }

  async searchAddresses(query) {
    const response = await this.fetch('Find', { Text: query, Countries: this._countries });
    return response.body.Items.filter(item => item.Type === 'Address');
  }

  async getAddress(addressId) {
    const response = await this.fetch('Retrieve', { Id: addressId });
    return response.body.Items[0];
  }

  async fetch(endPoint, options) {
    options.Key = this._key;
    return superagent
      .get(`https://api.addressy.com/Capture/Interactive/${endPoint}/v1.10/json3.ws`)
      .query(options);
  }
}

const api = new LoqateApi({ key: 'NA58-UW67-WA42-RD35', countries: ['US'] });

api.searchAddresses = debounce(api.searchAddresses, 200);

export default props => {
  const [addresses, setAddresses] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [mouseDown, setMousedown] = useState(false);

  const { fieldTouched, fieldError, errorClass } = errorValues(props);
  const {
    aptField,
    cityField,
    stateField,
    zipField,
    label,
    min,
    placeholder,
    defaultValue,
    maxLength,
    disabled,
    showValidation,
    field,
    form: { setFieldValue, setFieldTouched, setStatus }
  } = props;

  const classes = showValidation ? inputClassNames(props, 'form-control') : 'form-control';

  const handleChange = async evt => {
    const { value } = evt.target;
    setFieldValue(field.name, value);

    if (value && value.length > 3) {
      const result = await api.searchAddresses(evt.target.value);
      setAddresses(result);
    }
  };

  const setDisabledStatus = () =>
    setStatus({ disabled: [field.name, aptField, cityField, stateField, zipField] });

  const clearDisabledStatus = () => setStatus({ disabled: [''] });

  const findAddress = async (id, text) => {
    setFieldValue(field.name, text);
    setAddresses([]);

    try {
      const {
        BuildingNumber,
        Street,
        SubBuilding,
        Line1,
        City,
        Province,
        PostalCode
      } = await api.getAddress(id);
      if (aptField) {
        setFieldValue(field.name, SubBuilding ? `${BuildingNumber} ${Street}` : Line1);
        setFieldValue(aptField, SubBuilding);
      }
      setFieldValue(cityField, City);
      setFieldValue(stateField, Province);
      setFieldValue(zipField, PostalCode.substring(0, 5));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={errorClass}>
      <label>{label}</label>
      <div>
        <div className="autocomplete">
          <input
            {...field}
            defaultValue={defaultValue}
            type="text"
            maxLength={maxLength}
            min={min}
            disabled={disabled}
            onChange={handleChange}
            value={field.value || ''}
            placeholder={placeholder ? placeholder : label}
            onFocus={() => setHasFocus(true)}
            onBlur={() => {
              setHasFocus(false);
              setFieldTouched(field.name, true, true);
            }}
            className={classes}
          />
          {hasFocus && field.value && addresses && addresses.length > 0 && (
            <div className="autocomplete-items" onMouseDown={evt => evt.preventDefault()}>
              {addresses.map(({ Id, Text, Description }, key) => (
                <div key={key} onClick={() => findAddress(Id, Text)}>
                  <strong>{Text}</strong> {Description}
                </div>
              ))}
            </div>
          )}
          <div>{fieldTouched && fieldError && <span className="has-error">{fieldError}</span>}</div>
        </div>
      </div>
    </div>
  );
};
