import superagent from 'superagent';
import moment from 'moment';
import { push } from 'connected-react-router';
import apiClient from 'bv360/utils/api-client';
import getApiError from 'shared/utils/error';
import { buildUrl, URLS, urlRequiresLogin } from 'bv360/utils/urls';
const { BroadcastChannel } = require('broadcast-channel');
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash.isempty';

export const USER_GET = 'USER_GET';
export const USER_SSO_CHECK = 'USER_SSO_CHECK';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAIL = 'USER_GET_FAIL';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_NEEDED = 'USER_LOGIN_NEEDED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';

export const USER_NEW = 'USER_NEW';

export const USER_UPDATE_PREF = 'USER_UPDATE_PREF';

export const USER_ADD_MODAL_TOGGLE = 'USER_ADD_MODAL_TOGGLE';

export const USER_REGISTRATION = 'USER_REGISTRATION';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAIL = 'USER_REGISTRATION_FAIL';

export const USER_REGISTRATION_MODAL_TOGGLE = 'USER_REGISTRATION_MODAL_TOGGLE';

export const USER_GET_PRACTICES = 'USER_GET_PRACTICES';
export const USER_GET_PRACTICES_SUCCESS = 'USER_GET_PRACTICES_SUCCESS';
export const USER_GET_PRACTICES_FAIL = 'USER_GET_PRACTICES_FAIL';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAIL = 'DEACTIVATE_USER_FAIL';

export const EDIT_PRACTICE_USER = 'EDIT_PRACTICE_USER';
export const EDIT_PRACTICE_USER_SUCCESS = 'EDIT_PRACTICE_USER_SUCCESS';
export const EDIT_PRACTICE_USER_FAIL = 'EDIT_PRACTICE_USER_FAIL';

export const GET_PRACTICE_USERS = 'GET_PRACTICE_USERS';
export const GET_PRACTICE_USERS_SUCCESS = 'GET_PRACTICE_USERS_SUCCESS';
export const GET_PRACTICE_USERS_FAIL = 'GET_PRACTICE_USERS_FAIL';

export const ADD_PRACTICE_USER = 'ADD_PRACTICE_USER';
export const ADD_PRACTICE_USER_SUCCESS = 'ADD_PRACTICE_USER_SUCCESS';
export const ADD_PRACTICE_USER_FAIL = 'ADD_PRACTICE_USER_FAIL';

export const CONTACT_US = 'CONTACT_US';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL';

export const SEARCH_PRACTICES = 'SEARCH_PRACTICES';
export const SEARCH_PRACTICES_SUCCESS = 'SEARCH_PRACTICES_SUCCESS';
export const SEARCH_PRACTICES_FAIL = 'SEARCH_PRACTICES_FAIL';

export const START_DEMO = 'START_DEMO';
export const START_DEMO_SUCCESS = 'START_DEMO_SUCCESS';
export const START_DEMO_FAIL = 'START_DEMO_FAIL';

export const STOP_DEMO = 'STOP_DEMO';
export const STOP_DEMO_SUCCESS = 'STOP_DEMO_SUCCESS';
export const STOP_DEMO_FAIL = 'STOP_DEMO_FAIL';

export const GET_USER_COPILOT_DETAILS = 'GET_USER_COPILOT_DETAILS';
export const GET_USER_COPILOT_DETAILS_SUCCESS = 'GET_USER_COPILOT_DETAILS_SUCCESS';
export const GET_USER_COPILOT_DETAILS_FAIL = 'GET_USER_COPILOT_DETAILS_FAIL';

export const CLEAR_COPILOT_DETAILS = 'CLEAR_COPILOT_DETAILS';

export const CLEAR_PRACTICE_EDIT_ERRORS = 'CLEAR_PRACTICE_EDIT_ERRORS';

export const GET_CONFIRM_USER_INFO = 'GET_CONFIRM_USER_INFO';
export const GET_CONFIRM_USER_INFO_SUCCESS = 'GET_CONFIRM_USER_INFO_SUCCESS';
export const GET_CONFIRM_USER_INFO_FAIL = 'GET_CONFIRM_USER_INFO_FAIL';

export const SET_CONFIRM_USER_INFO = 'SET_CONFIRM_USER_INFO';
export const SET_CONFIRM_USER_INFO_SUCCESS = 'SET_CONFIRM_USER_INFO_SUCCESS';
export const SET_CONFIRM_USER_INFO_FAIL = 'SET_CONFIRM_USER_INFO_FAIL';

export const TOGGLE_CONFIRM_USER_RESULT_MODAL = 'TOGGLE_CONFIRM_USER_RESULT_MODAL';

const reloadChannel = new BroadcastChannel('bv360_broadcast_reload_channel');

reloadChannel.onmessage = msg => {
  window.location.reload();
};

export function clearCopilotDetails() {
  return dispatch => {
    dispatch({ type: CLEAR_COPILOT_DETAILS });
  };
}

export function getUserCopilotDetails(userInfo) {
  return async dispatch => {
    dispatch({ type: GET_USER_COPILOT_DETAILS });

    try {
      if (!userInfo?.user?.username) throw 'User email not found';

      const response = await apiClient.get('/mybv360/api/copilot-get-user-details', {
        // params: {email: 'allen@ivc.com'},
        // params: {email: 'biovtest4@gmail.com'},
        // params: {email: 'mybv360@bioventus.com'},
        params: { email: userInfo?.user?.username },
        timeout: 100000
      });
      if (response?.body?.data) {
        dispatch({ type: GET_USER_COPILOT_DETAILS_SUCCESS, user_details: response?.body?.data });
      }
    } catch (err) {
      const error = err ? getApiError(err) : 'Error Getting Practices';

      dispatch({ type: GET_USER_COPILOT_DETAILS_FAIL, error });
    }
  };
}

export function getPractices(practiceInfo = {}, isUpdate = false) {
  return async (dispatch, getState) => {
    if (isEmpty(practiceInfo)) {
      const appState = getState();
      const isSystemsAdmin =
        (appState.user.user && appState.user.user.groups && appState.user.user.is_superuser) ||
        false;
      const isBV360CustomerService =
        appState?.user?.user?.groups?.includes('BV360 Customer Service') || false;

      const isPracticeinStore = appState.user.practices && appState.user.practices.length;
      const removedDate =
        (appState.user.practices &&
          appState.user.practices.length &&
          appState.user.practices[0].removed_date) ||
        false;

      if (
        (isSystemsAdmin || isBV360CustomerService) &&
        isPracticeinStore &&
        !removedDate &&
        !isUpdate
      ) {
        practiceInfo = { name: appState.user.practices[0].name, id: appState.user.practices[0].id };
      }
    }

    // dispatch action to clear errors
    dispatch({ type: CLEAR_PRACTICE_EDIT_ERRORS });
    dispatch({ type: USER_GET_PRACTICES });

    try {
      const response = await apiClient.get('/mybv360/api/get-practices', {
        params: practiceInfo,
        timeout: 100000
      });
      if (response?.body?.data) {
        dispatch({
          type: USER_GET_PRACTICES_SUCCESS,
          practices: response.body.data,
          hasFurtherAction: true
        });
        // practiceInfo will be empty if none are passed into getPractices
        dispatch(getPracticeUsers());
      } else {
        const error = 'Error Getting Practices';
        dispatch({ type: USER_GET_PRACTICES_FAIL, error });
      }
    } catch (err) {
      const error = err ? getApiError(err) : 'Error Getting Practices';
      dispatch({ type: USER_GET_PRACTICES_FAIL, error });
    }
  };
}

export function startDemo() {
  return dispatch => {
    dispatch(push(URLS.BASE));
    dispatch({ type: START_DEMO });
    apiClient
      .post('/mybv360/api/demo/start')
      .then(response => {
        reloadChannel.postMessage('reloading for demo mode');
        window.location.reload();
      })
      .catch(error => dispatch({ type: START_DEMO_FAIL, error: getApiError(error) }));
  };
}

export function stopDemo() {
  return dispatch => {
    dispatch(push(URLS.BASE));
    dispatch({ type: STOP_DEMO });
    apiClient
      .post('/mybv360/api/demo/stop')
      .then(response => {
        reloadChannel.postMessage('reloading for demo mode');
        window.location.reload();
      })
      .catch(error => dispatch({ type: STOP_DEMO_FAIL, error: getApiError(error) }));
  };
}

export function logout() {
  return dispatch => {
    dispatch(push(URLS.LOGIN));
    dispatch({ type: USER_LOGOUT });
    apiClient
      .post('/mybv360/api/logout')
      .then(response => {
        const result = response.body;
        dispatch({ type: USER_LOGOUT_SUCCESS, result });
      })
      .catch(error => dispatch({ type: USER_LOGOUT_FAIL, error }));
  };
}

export function addUserModalToggle() {
  return {
    type: USER_ADD_MODAL_TOGGLE
  };
}

const addUser = (user, dispatch) =>
  new Promise(resolve => {
    dispatch({ type: USER_GET_SUCCESS, user });
    resolve();
  });

const newUser = id => {
  localStorage.setItem('user_id', id);

  return {
    type: USER_NEW
  };
};

const updateTrackingContext = user => {
  if (__SENTRY__) {
    const context = user.username ? { email: user.username, id: user.id } : { id: user.id };

    Sentry.configureScope(scope => {
      scope.setUser(context);
    });
  }
};

export function goToSSO(redirect = '') {
  return dispatch => {
    dispatch(push(buildUrl(`/sso?r=${redirect}`)));
  };
}

export function registerUser(data) {
  return dispatch => {
    dispatch({ type: USER_REGISTRATION });
    superagent
      .post(`/mybv360/api/register`)
      .send(data)
      .timeout({
        response: 50000, // Wait 30 seconds for the server to start sending,
        deadline: 100000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, { body = null } = {}) => {
        if (!err) {
          dispatch({ type: USER_REGISTRATION_SUCCESS });
          window.location.replace(
            `/mybv360/app/registration/success?npi=${data?.practice?.npi}&practice=${data?.practice?.name}`
          );
        } else {
          const error = getApiError(err);
          dispatch({
            type: USER_REGISTRATION_FAIL,
            error,
            officesWithUsers: body?.officesWithUsers || []
          });
        }
      });
  };
}

export function toggleUserRegistrationModal() {
  return {
    type: USER_REGISTRATION_MODAL_TOGGLE
  };
}

export function updateUser(data = {}) {
  return dispatch => {
    dispatch({ type: USER_UPDATE });
    superagent
      .put('/mybv360/api/update')
      .send(data)
      .timeout({
        response: 5000, // Wait 30 seconds for the server to start sending,
        deadline: 10000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, { body = null } = {}) => {
        if (!err) {
          dispatch({ type: USER_UPDATE_SUCCESS });
          const isUpdate = true;
          dispatch(getCurrentUser(isUpdate));
          dispatch(push(URLS.BASE));
        } else {
          const error = getApiError(err);

          if (err.response.body && err.response.body.errorType === 'practice') {
            const practice_edit_error = error;
            dispatch({ type: USER_UPDATE_FAIL, error: null, practice_edit_error });
          } else {
            dispatch({ type: USER_UPDATE_FAIL, error });
          }
        }
      });
  };
}

export function getCurrentUser(isUpdate = false) {
  return (dispatch, getState) => {
    const appState = getState();
    const { pathname } = appState.router.location;
    const { user } = getState().user;

    dispatch({ type: USER_GET });
    superagent
      .get('/mybv360/api/user/')
      .timeout({
        response: 30000, // Wait 30 seconds for the server to start sending,
        deadline: 60000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, { body = null } = {}) => {
        if (!err && body && body.user) {
          if (user && body.user.hasOwnProperty('id') && body.user.id !== user.id) {
            dispatch(newUser(body.user.id));
            dispatch(getPractices({}, isUpdate));
          }

          updateTrackingContext(body.user);

          addUser(body.user, dispatch).then(() => {
            localStorage.setItem('user_id', body.user.id);
            dispatch({ type: USER_SSO_CHECK, ssoComplete: moment.utc() });
          });
        } else {
          dispatch({ type: USER_GET_FAIL, ssoComplete: moment.utc() });
          if (urlRequiresLogin(pathname)) {
            dispatch(push(URLS.LOGIN));
          }
        }
      });
  };
}

export function updateUserPrefs(prefs) {
  return {
    type: USER_UPDATE_PREF,
    prefs
  };
}

export function deactivateUser(data) {
  return dispatch => {
    dispatch({ type: DEACTIVATE_USER });
    superagent
      .put('/mybv360/api/deactivate-user')
      .send(data)
      .timeout({
        response: 5000, // Wait 30 seconds for the server to start sending,
        deadline: 10000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, { body = null } = {}) => {
        if (!err) {
          dispatch({ type: DEACTIVATE_USER_SUCCESS });
          dispatch(getPracticeUsers());
        } else {
          const error = getApiError(err);
          dispatch({ type: DEACTIVATE_USER_FAIL, error });
        }
      });
  };
}

export function editPracticeUser(data) {
  return dispatch => {
    dispatch({ type: EDIT_PRACTICE_USER });
    superagent
      .put('/mybv360/api/edit-practice-user')
      .send(data)
      .timeout({
        response: 5000, // Wait 30 seconds for the server to start sending,
        deadline: 10000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, { body = null } = {}) => {
        if (!err) {
          dispatch({ type: EDIT_PRACTICE_USER_SUCCESS });

          dispatch(getPracticeUsers());
        } else {
          const error = getApiError(err);
          dispatch({ type: EDIT_PRACTICE_USER_FAIL, error });
        }
      });
  };
}

const faxRequiredErrors = [
  'Existing user not in BV360 Reimbursement Solution. Please provide a fax number and resubmit.',
  "Cannot clone this user's permissions. Please provide a fax number and resubmit."
];

export function addPracticeUser(data) {
  return dispatch => {
    dispatch({ type: ADD_PRACTICE_USER });
    superagent
      .post('/mybv360/api/add-practice-user')
      .send(data)
      .timeout({
        response: 10000, // Wait 30 seconds for the server to start sending,
        deadline: 10000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, body) => {
        if (!err) {
          dispatch({ type: ADD_PRACTICE_USER_SUCCESS });
          data.id = body.body.practiceUserMapID; // edit practice user needs an id key, roles are added in the edit practice user
          dispatch(editPracticeUser(data));
        } else {
          const error = getApiError(err);
          const requireFax = faxRequiredErrors.includes(error);

          dispatch({ type: ADD_PRACTICE_USER_FAIL, error, requireFax });
        }
      });
  };
}

export function getPracticeUsers() {
  return (dispatch, getState) => {
    const appState = getState();
    const isSystemsAdmin =
      (appState.user.user && appState.user.user.groups && appState.user.user.is_superuser) || false;

    const isBV360CustomerService =
      appState?.user?.user?.groups?.includes('BV360 Customer Service') || false;

    const isPracticeinStore = appState.user.practices && appState.user.practices.length;

    let practiceInfo = {};
    if ((isSystemsAdmin || isBV360CustomerService) && isPracticeinStore) {
      practiceInfo = { name: appState.user.practices[0].name, id: appState.user.practices[0].id };
    }

    dispatch({ type: GET_PRACTICE_USERS });
    superagent
      .get('/mybv360/api/get-practice-users')
      .query(practiceInfo)
      .timeout({
        response: 50000, // Wait 30 seconds for the server to start sending,
        deadline: 100000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, body) => {
        if (!err) {
          // compile the current logged on user and the others in the practice
          let compiled_users = [];
          compiled_users.push(body.body.current_user);
          compiled_users.splice(1, 0, ...body.body.others);

          // make a string of comma seperated combined roles in the object ".compiled_roles"
          compiled_users = compiled_users.map(val => {
            let compiled_roles = [];

            if (val.role) {
              const npi_number = val.profile?.physician?.npi_number;

              if (val.is_admin) {
                compiled_roles.push('Practice Administrator, ');
              }

              if (val.role.display_name === 'Physician' && npi_number) {
                compiled_roles.push(val.role.display_name + ` (${npi_number})`);
              } else {
                compiled_roles.push(val.role.display_name);
              }

              val.compiled_roles = compiled_roles;

            }

            return val;
          });

          dispatch({ type: GET_PRACTICE_USERS_SUCCESS, compiled_users });
        } else {
          const error = getApiError(err);
          dispatch({ type: GET_PRACTICE_USERS_FAIL, error });
        }
      });
  };
}

export function sendContactUsEmail(data = {}, closeModal) {
  return dispatch => {
    dispatch({ type: CONTACT_US });
    superagent
      .post('/mybv360/api/send-contactus-email')
      .send(data)
      .timeout({
        response: 50000, // Wait 30 seconds for the server to start sending,
        deadline: 100000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, body) => {
        if (!err) {
          dispatch({ type: CONTACT_US_SUCCESS });
          closeModal();
        } else {
          const error = getApiError(err);
          dispatch({ type: CONTACT_US_FAIL, error });
        }
      });
  };
}

export function searchPractices(data = {}) {
  return async dispatch => {
    try {
      dispatch({ type: SEARCH_PRACTICES });
      const response = await apiClient.get('/mybv360/api/practices', {
        params: { ...data.practice }
      });
      dispatch({ type: SEARCH_PRACTICES_SUCCESS, admin_practice_names: response.body.data });
    } catch (err) {
      const error = getApiError(err);
      dispatch({ type: SEARCH_PRACTICES_FAIL, error });
    }
  };
}

export function getConfirmUserInfo(data = {}) {
  return async dispatch => {
    try {
      dispatch({ type: GET_CONFIRM_USER_INFO });
      const response = await apiClient.get('/mybv360/api/get-confirm-user-info', {
        params: { ...data }
      });
      dispatch({ type: GET_CONFIRM_USER_INFO_SUCCESS, userToBeConfirmed: response.body.data });
    } catch (err) {
      const error = getApiError(err);
      dispatch({ type: GET_CONFIRM_USER_INFO_FAIL, error });
    }
  };
}

export function setConfirmUserInfo(data = {}) {
  return async dispatch => {
    try {
      dispatch({ type: SET_CONFIRM_USER_INFO });
      const response = await apiClient.put('/mybv360/api/set-confirm-user-info', {
        params: { ...data }
      });
      dispatch({ type: SET_CONFIRM_USER_INFO_SUCCESS });
      dispatch({ type: TOGGLE_CONFIRM_USER_RESULT_MODAL, open: true });
    } catch (err) {
      const error = getApiError(err);
      dispatch({ type: SET_CONFIRM_USER_INFO_FAIL, error });
      dispatch({ type: TOGGLE_CONFIRM_USER_RESULT_MODAL, open: true });
    }
  };
}

export function toggleSetConfirmUserResultModal(open) {
  return dispatch => {
    dispatch({ type: TOGGLE_CONFIRM_USER_RESULT_MODAL, open: open });
  };
}
