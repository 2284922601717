import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isSapStatusOpen } from 'shared/utils/store';
import { Groups } from 'shared/utils/constants';

import * as ExogenOrderActions from 'exogen/redux/actions/orders';
import * as RulesActions from 'exogen/redux/actions/rules';

export function OrderState() {
  const {
    currentOrderId,
    currentOrder,
    esignAllowAll,
    formData,
    isOpenOrder,
    networkStatus,
    orderSubmitting,
    orderCancel,
    orderLocation,
    showSubmissionModal,
    showValidationModal,
    submissionErrors,
    submitType,
    territories,
    user,
    validation,
    IPCLoading,
    IPCError,
    draftIncompleteReasons,
    // these are the newly created notes
    notes,
    send_contact_us_email_loading,
    send_contact_us_email_error,
    draftSaving
  } = useSelector(state => ({
    draftIncompleteReasons: state.formData.draftDelayReasons,
    currentOrderId: state.orders.currentOrderId,
    currentOrder: state.orders.currentOrder,
    esignAllowAll: state.formData.systemConfig?.esignAllowAll,
    formData: state.formData,
    isOpenOrder: isSapStatusOpen(state.orders),
    networkStatus: state.network?.status,
    orderSubmitting: state.orders.orderSubmitting,
    orderCancel: state.orders.orderCancel,
    orderLocation: state.orders.orderLocation,
    payerId: state?.rules?.payerId,
    showSubmissionModal: state.orders.showSubmissionModal,
    showValidationModal: state?.rules?.showValidationModal,
    submissionErrors: state.orders.submissionErrors,
    submitType: state.orders.submitType,
    territories: state.orders.territories,
    user: state.user.user,
    validation: state?.rules?.validation,
    IPCLoading: state.orders.IPCLoading,
    IPCError: state.orders.IPCError,
    notes: state.orders.orders.notes,
    send_contact_us_email_loading: state.reporting?.send_contact_us_email_loading,
    send_contact_us_email_error: state.reporting?.send_contact_us_email_error,
    draftSaving: state.orders.draftSaving
  }));

  const omUser = user?.groups?.includes(Groups.ExogenOrderManager);
  const momUser = user?.groups?.includes(Groups.ExogenMom);
  const isDraft = orderLocation === 'drafts';
  const isSavedDraft = isDraft && !String(currentOrderId).startsWith('tmp') ? true : false;
  const myId = user?.id;
  const clientId = currentOrder?.client_id;

  const isMyOrder =
    omUser ||
    (isDraft && currentOrder?.owned_by?.id === myId) ||
    (!isDraft && currentOrder?.created_by?.id === myId);

  return {
    currentOrderId,
    currentOrder,
    esignAllowAll,
    formData,
    isDraft,
    isMyOrder,
    isOpenOrder,
    isSavedDraft,
    networkStatus,
    orderSubmitting,
    orderCancel,
    orderLocation,
    showSubmissionModal,
    showValidationModal,
    submissionErrors,
    submitType,
    territories,
    user,
    validation,
    omUser,
    momUser,
    IPCLoading,
    IPCError,
    draftIncompleteReasons,
    notes,
    clientId,
    send_contact_us_email_loading,
    send_contact_us_email_error,
    draftSaving
  };
}

export function OrderActions() {
  const dispatch = useDispatch();

  const getDuplicatePatientInfo = (...params) =>
    dispatch(ExogenOrderActions.getDuplicatePatientInfo(...params));

  const cancelOrder = ({ orderId, orderLocation, reason }) =>
    dispatch(ExogenOrderActions.cancelOrder({ orderId, orderLocation, reason }));

  const clearCurrentOrder = (...params) =>
    dispatch(ExogenOrderActions.clearCurrentOrder(...params));

  const deleteIfClean = (...params) => dispatch(ExogenOrderActions.deleteIfClean(...params));

  const openOrderFulfillment = (...params) =>
    dispatch(ExogenOrderActions.openOrderFulfillment(...params));
  const saveChanges = (...params) => dispatch(ExogenOrderActions.saveOmChanges(...params));

  const setRulesPayer = (...params) => dispatch(RulesActions.setRulesPayer(...params));

  const setSubmitType = (...params) => dispatch(ExogenOrderActions.setSubmitType(...params));

  const toggleCancelOrderModal = (...params) =>
    dispatch(ExogenOrderActions.toggleCancelOrderModal(...params));

  const toggleSubmissionModal = (...params) =>
    dispatch(ExogenOrderActions.toggleSubmissionModal(...params));

  const toggleValidationModal = (...params) =>
    dispatch(RulesActions.toggleValidationModal(...params));

  const validateOrder = (...params) => dispatch(RulesActions.validateOrder(...params));

  return {
    getDuplicatePatientInfo,
    cancelOrder,
    clearCurrentOrder,
    deleteIfClean,
    openOrderFulfillment,
    saveChanges,
    setRulesPayer,
    setSubmitType,
    toggleCancelOrderModal,
    toggleSubmissionModal,
    toggleValidationModal,
    validateOrder
  };
}
