export const DRAFT_DELETE = 'DRAFT_DELETE';

export const DRAFTS_ALL = 'DRAFTS_ALL';
export const DRAFTS_ALL_SUCCESS = 'DRAFTS_ALL_SUCCESS';
export const DRAFTS_ALL_FAIL = 'DRAFTS_ALL_FAIL';

export const DRAFT_SUBMIT = 'DRAFT_SUBMIT';
export const DRAFT_SUBMIT_SUCCESS = 'DRAFT_SUBMIT_SUCCESS';
export const DRAFT_SUBMIT_FAIL = 'DRAFT_SUBMIT_FAIL';

export const FULFILLMENT_SUBMIT = 'FULFILLMENT_SUBMIT';
export const FULFILLMENT_SUBMIT_PROGRESS = 'FULFILLMENT_SUBMIT_PROGRESS';
export const FULFILLMENT_SUBMIT_SUCCESS = 'FULFILLMENT_SUBMIT_SUCCESS';
export const FULFILLMENT_SUBMIT_FAIL = 'FULFILLMENT_SUBMIT_FAIL';

export const GUIDELINE_CONVERT = 'GUIDELINE_CONVERT';

export const INVENTORY_ALL = 'INVENTORY_ALL';
export const INVENTORY_ALL_SUCCESS = 'INVENTORY_ALL_SUCCESS';
export const INVENTORY_ALL_FAIL = 'INVENTORY_ALL_FAIL';

export const MA_DELETE = 'MA_DELETE';
export const MA_DELETE_SUCCESS = 'MA_DELETE_SUCCESS';
export const MA_DELETE_FAIL = 'MA_DELETE_FAIL';

export const NEW_GUIDELINE = 'NEW_GUIDELINE';
export const NEW_ORDER = 'NEW_ORDER';

export const ORDER_GET = 'ORDER_GET';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_FAIL = 'ORDER_GET_FAIL';

export const ORDER_CLEAR_CURRENT = 'ORDER_CLEAR_CURRENT';

export const ORDER_CANCEL = 'ORDER_CANCEL';
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';
export const ORDER_CANCEL_FAIL = 'ORDER_CANCEL_FAIL';
export const ORDER_CANCEL_MODAL_TOGGLE = 'ORDER_CANCEL_MODAL_TOGGLE';

export const ORDER_NOTES = 'ORDER_NOTES';
export const ORDER_NOTES_SUCCESS = 'ORDER_NOTES_SUCCESS';
export const ORDER_NOTES_FAIL = 'ORDER_NOTES_FAIL';

export const ORDERS_ALL = 'ORDERS_ALL';
export const ORDERS_ALL_SUCCESS = 'ORDERS_ALL_SUCCESS';
export const ORDERS_ALL_FAIL = 'ORDERS_ALL_FAIL';

export const ORDERS_CLEAR = 'ORDERS_CLEAR';

export const ORDERS_DOCUSIGN = 'ORDERS_DOCUSIGN';
export const ORDERS_DOCUSIGN_SUCCESS = 'ORDERS_DOCUSIGN_SUCCESS';
export const ORDERS_DOCUSIGN_FAIL = 'ORDERS_DOCUSIGN_FAIL';

export const ORDERS_NOTES = 'ORDERS_NOTES';
export const ORDERS_NOTES_SUCCESS = 'ORDERS_NOTES_SUCCESS';
export const ORDERS_NOTES_FAIL = 'ORDERS_NOTES_FAIL';

export const ORDERS_SHIPPING = 'ORDERS_SHIPPING';
export const ORDERS_SHIPPING_SUCCESS = 'ORDERS_SHIPPING_SUCCESS';
export const ORDERS_SHIPPING_FAIL = 'ORDERS_SHIPPING_FAIL';

export const ORDERS_SHIPPING_FAX = 'ORDERS_SHIPPING_FAX';
export const ORDERS_SHIPPING_SUCCESS_FAX = 'ORDERS_SHIPPING_SUCCESS_FAX';
export const ORDERS_SHIPPING_FAIL_FAX = 'ORDERS_SHIPPING_FAIL_FAX';

export const ORDERS_SHIPPING_MODAL = 'ORDERS_SHIPPING_MODAL';

export const ORDER_SUBMIT = 'ORDER_SUBMIT';
export const ORDER_SUBMIT_SUCCESS = 'ORDER_SUBMIT_SUCCESS';

export const ORDER_TEST = 'ORDER_TEST';
export const ORDER_TEST_SUCCESS = 'ORDER_TEST_SUCCESS';
export const ORDER_TEST_FAIL = 'ORDER_TEST_FAIL';

export const ORDER_SUBMIT_FAIL = 'ORDER_SUBMIT_FAIL';
export const ORDER_SUBMIT_ERRORS = 'ORDER_SUBMIT_ERRORS';
export const ORDER_VALIDATE = 'ORDER_VALIDATE';
export const ORDER_CLEANUP = 'ORDER_CLEANUP';

export const GUIDELINE_SAVE_CHANGES = 'GUIDELINE_SAVE_CHANGES';

export const PATIENT_ADVOCACY = 'PATIENT_ADVOCACY';
export const PATIENT_ADVOCACY_SUCCESS = 'PATIENT_ADVOCACY_SUCCESS';
export const PATIENT_ADVOCACY_FAIL = 'PATIENT_ADVOCACY_FAIL';

export const ORDER_SAVE_CHANGES = 'ORDER_SAVE_CHANGES';

export const ORDER_ADVOCACY_TOGGLE_MODAL = 'ORDER_ADVOCACY_TOGGLE_MODAL';

export const ORDER_SUBMISSION_TOGGLE_MODAL = 'ORDER_SUBMISSION_TOGGLE_MODAL';

export const REQUEST_LIVE_FITTING = 'REQUEST_FITTING';
export const REQUEST_LIVE_FITTING_SUCCESS = 'REQUEST_FITTING_SUCCESS';
export const REQUEST_LIVE_FITTING_FAIL = 'REQUEST_FITTING_FAIL';

export const REQUEST_DRAFT_LIVE_FITTING = 'REQUEST_DRAFT_FITTING';
export const REQUEST_DRAFT_LIVE_FITTING_SUCCESS = 'REQUEST_DRAFT_FITTING_SUCCESS';
export const REQUEST_DRAFT_LIVE_FITTING_FAIL = 'REQUEST_DRAFT_FITTING_FAIL';

export const CANCEL_LIVE_FITTING = 'CANCEL_FITTING';
export const CANCEL_LIVE_FITTING_SUCCESS = 'CANCEL_FITTING_SUCCESS';
export const CANCEL_LIVE_FITTING_FAIL = 'CANCEL_FITTING_FAIL';

export const CANCEL_DRAFT_LIVE_FITTING = 'CANCEL_DRAFT_FITTING';
export const CANCEL_DRAFT_LIVE_FITTING_SUCCESS = 'CANCEL_DRAFT_FITTING_SUCCESS';
export const CANCEL_DRAFT_LIVE_FITTING_FAIL = 'CANCEL_DRAFT_FITTING_FAIL';

export const REQUEST_HELP = 'REQUEST_HELP';
export const REQUEST_HELP_SUCCESS = 'REQUEST_HELP_SUCCESS';
export const REQUEST_HELP_FAIL = 'REQUEST_HELP_FAIL';

export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const SET_SUBMIT_TYPE = 'SET_SUBMIT_TYPE';

export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';

export const SERIAL_VALIDATION = 'SERIAL_VALIDATION';
export const SERIAL_VALIDATION_FAIL = 'SERIAL_VALIDATION_FAIL';
export const SERIAL_VALIDATION_SUCCESS = 'SERIAL_VALIDATION_SUCCESS';
export const SERIAL_VALIDATION_RESET = 'SERIAL_VALIDATION_RESET';

export const SET_DRAFT_AS_ORDER = 'SET_DRAFT_AS_ORDER';

export const TOGGLE_SELF_PAY = 'TOGGLE_SELF_PAY';

export const TOGGLE_SECOND_PAYER = 'TOGGLE_SECOND_PAYER';

export const TERRITORIES = 'TERRITORIES';
export const TERRITORIES_SUCCESS = 'TERRITORIES_SUCCESS';
export const TERRITORIES_FAIL = 'TERRITORIES_FAIL';

export const STAGES = 'STAGES';
export const STAGES_SUCCESS = 'STAGES_SUCCESS';
export const STAGES_FAIL = 'STAGES_FAIL';

export const UPDATE_ORDER = 'UPDATE_ORDER';

export const TOGGLE_ORDER_HISTORY_MODAL = 'TOGGLE_ORDER_HISTORY_MODAL';

export const TOGGLE_SHOW_MORE = 'TOGGLE_SHOW_MORE';

export const UPDATE_IPC_TEXT = 'UPDATE_IPC_TEXT';
export const UPDATE_IPC_TEXT_SUCCESS = 'UPDATE_IPC_TEXT_SUCCESS';
export const UPDATE_IPC_TEXT_FAIL = 'UPDATE_IPC_TEXT_FAIL';

export const SET_REASONS_LIST = 'SET_REASONS_LIST';

export const GET_DUPLICATE_PATIENT_INFO = 'GET_DUPLICATE_PATIENT_INFO';
export const GET_DUPLICATE_PATIENT_INFO_SUCCESS = 'GET_DUPLICATE_PATIENT_INFO_SUCCESS';
export const GET_DUPLICATE_PATIENT_INFO_FAIL = 'GET_DUPLICATE_PATIENT_INFO_FAIL';

export const SEND_PATIENT_EMAIL = 'SEND_PATIENT_EMAIL';
export const SEND_PATIENT_EMAIL_DRAFT_SUCCESS = 'SEND_PATIENT_EMAIL_DRAFT_SUCCESS';
export const SEND_PATIENT_EMAIL_ORDER_SUCCESS = 'SEND_PATIENT_EMAIL_ORDER_SUCCESS';
export const SEND_PATIENT_EMAIL_FAIL = 'SEND_PATIENT_EMAIL_FAIL';

export const DRAFT_SAVING_FALSE = 'DRAFT_SAVING_FALSE';

export const UPDATE_PATIENT_EMAIL = 'UPDATE_PATIENT_EMAIL';
export const UPDATE_PATIENT_EMAIL_SUCCESS = 'UPDATE_PATIENT_EMAIL_SUCCESS';
export const UPDATE_PATIENT_EMAIL_FAIL = 'UPDATE_PATIENT_EMAIL_FAIL';

export const SEND_REQUEST_TO_PFS = 'SEND_REQUEST_TO_PFS';
export const SEND_REQUEST_TO_PFS_SUCCESS = 'SEND_REQUEST_TO_PFS_SUCCESS';
export const SEND_REQUEST_TO_PFS_FAIL = 'SEND_REQUEST_TO_PFS_FAIL';