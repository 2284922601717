const libphonenumber = require('libphonenumber-js');

export const payerName = payer => {
  const type =
    payer && payer?.payer_type__type_name
      ? payer.payer_type__type_name.charAt(0).toUpperCase() + payer.payer_type__type_name.slice(1)
      : null;
  let payerDisplay = type ? `${payer?.name}, ${type}` : payer?.name;
  if (payer?.city && payer?.state) {
    payerDisplay += `, ${payer.city}, ${payer.state}`;
  } else if (payer?.city) {
    payerDisplay += `, ${payer.city}`;
  }
  return payerDisplay;
};

export const physicianName = ({ first_name, last_name, name, address1, city, zip }) =>
  `${first_name} ${last_name} ${name} ${address1} ${city} ${zip}`;

export const physicianNameRx = physician => {
  let physicianDisplay = `Dr ${physician.first_name} ${physician.last_name}`;

  if (physician && physician.office_name) {
    physicianDisplay += `, ${physician.office_name}`;
  }

  if (physician.city && physician.state) {
    physicianDisplay += `, ${physician.city}, ${physician.state}`;
  } else if (physician.city) {
    physicianDisplay += `, ${physician.city}`;
  }

  return physicianDisplay;
};

export const isEmpty = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const formatName = patient => {
  if (!patient || isEmpty(patient)) return 'Not Entered';
  let name = '';
  const { first_name, middle_initial, last_name } = patient;
  if (first_name) name += first_name;
  if (middle_initial) name += ` ${middle_initial}`;
  if (last_name) name += ` ${last_name}`;

  return name;
};

export const formatRepName = ({ creator_title, created_by, last_initial=false }) => {
  if (creator_title) {
    return creator_title;
  }
  if (!created_by || isEmpty(created_by)) return 'Name missing';
  let name = '';
  const { first_name, last_name } = created_by;
  if (first_name) name += first_name;
  if(last_initial) {
    if (last_name) name += ` ${last_name[0]}.`;
  } else{
    if (last_name) name += ` ${last_name}`;
  }

  return name;
};

export const phoneFormatter = number =>
  !number ? '' : libphonenumber.format(number, 'US', 'National');
export const phoneParser = number => (number ? number.replace(/[-() ]/g, '') : '');
