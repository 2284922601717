import superagent from 'superagent';
import moment from 'moment';
import { push } from 'connected-react-router';
import apiClient from 'exogen/utils/api-client';
import { Groups } from 'shared/utils/constants';
import * as Sentry from '@sentry/browser';
const { BroadcastChannel } = require('broadcast-channel');
import { NetworkStatus } from 'shared/utils/constants';

export const USER_GET = 'USER_GET';
export const USER_SSO_CHECK = 'USER_SSO_CHECK';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAIL = 'USER_GET_FAIL';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_NEEDED = 'USER_LOGIN_NEEDED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';

export const USER_NEW = 'USER_NEW';

export const USER_UPDATE_PREF = 'USER_UPDATE_PREF';

const { ONLINE, READY } = NetworkStatus;

const reloadChannel = new BroadcastChannel('bv360_broadcast_reload_channel');

reloadChannel.onmessage = msg => {
  console.log(msg);
  window.location.reload();
};

export function login(username, password) {
  return (dispatch, getState) => {
    const { router, user } = getState();
    let redirectTo = '/';
    dispatch({ type: USER_LOGIN });
    apiClient
      .post(`/login/`, { type: 'form', params: { username, password } })
      .then(response => {
        const result = response.body;

        if (!user.user || result.user.id !== user.user.id) {
          dispatch(newUser(result.user.id));
        }
        updateTrackingContext(result.user);

        dispatch({ type: USER_LOGIN_SUCCESS, result });
        if (localStorage.getItem('deepLinkRedirect')) {
          redirectTo = localStorage.getItem('deepLinkRedirect');
          localStorage.removeItem('deepLinkRedirect');
        }

        dispatch(push(redirectTo));
      })
      .catch(error => dispatch({ type: USER_LOGIN_FAIL, error }));
  };
}

export function loginOkta(username, password) {
  function gonative_status_afterlogin(data) {
    console.log('Go native save creds test post login');
    // alert(`Go native post login save creds? ${data && data.hasTouchId}`);
    if (data && data.hasTouchId) {
      console.log('Go native  saving creds post login');
      var secret = JSON.stringify({
        username: username,
        password: password
      });

      gonative.auth.save({ secret: secret, minimumAndroidBiometric: 'strong' });
    }
  }

  return (dispatch, getState) => {
    const { router, user } = getState();
    let redirectTo = '/';
    dispatch({ type: USER_LOGIN });
    apiClient
      .post(`/okta-login/`, { type: 'form', params: { username, password } })
      .then(response => {
        const result = response.body;
        // ssoComplete here?
        console.log('sso5', moment.utc());
        dispatch({ type: USER_SSO_CHECK, ssoComplete: moment.utc() });
        if (!user.user || result.user.id !== user.user.id) {
          dispatch(newUser(result.user.id));
        }
        updateTrackingContext(result.user);

        console.log(`Go native test ${navigator.userAgent.indexOf('gonative')}`);
        if (navigator.userAgent.indexOf('gonative') > -1) {
          // alert('Go native found...post login function called next');
          console.log('Go native found...post login function called next');
          gonative.auth.status({ callbackFunction: gonative_status_afterlogin });

          // oneSignal info contains information about the device see https://gonative.readme.io/docs/onesignal-info
          // const oneSignalInfo = gonative.onesignal.run.onesignalInfo();
          // console.log('one signal info',oneSignalInfo)
          // console.log('one signal info',gonative.onesignal)
          // const oneSignalUserId = oneSignalInfo.oneSignalUserId
          gonative.onesignal.onesignalInfo().then(function(oneSignalInfo) {
            console.log('OneSignal Info retrieved', oneSignalInfo);
            if (oneSignalInfo) {
              console.log(
                `OneSignal associating ${oneSignalInfo.oneSignalUserId} with ${result.user?.username}`
              );
              apiClient
                .post('/associate-user-and-onesignal-device/', {
                  params: { onesignal_player_id: oneSignalInfo.oneSignalUserId }
                })
                .then(response => {
                  console.log('response from associate', response);
                })
                .catch(e => {
                  console.log('onesignal player id association with username failed');
                });
            }
            // put the association here
          });
          //associate user with this device in ED
          // apiClient.post('/associate_username_and_onesignal_device/', {params: {onesignal_player_id: oneSignalUserId}})

          // after login if gonative exists, deregister any existing onesignal external ids
          // and register the newly logged in user
          gonative.onesignal.externalUserId.remove();
          console.log('Setting one signal external id to: ', result.user?.username);
          gonative.onesignal.externalUserId.set({
            externalId: result.user?.username || 'unknown user'
          });
        }

        dispatch({ type: USER_LOGIN_SUCCESS, result });
        if (localStorage.getItem('deepLinkRedirect')) {
          redirectTo = localStorage.getItem('deepLinkRedirect');
          localStorage.removeItem('deepLinkRedirect');
        }

        dispatch(push(redirectTo));
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: USER_LOGIN_FAIL, error });
      });
  };
}

export function logout() {
  return dispatch => {
    dispatch(push('/login'));
    dispatch({ type: USER_LOGOUT });
    apiClient
      .post(`/logout/`)
      .then(response => {
        const result = response.body;
        dispatch({ type: USER_LOGOUT_SUCCESS, result });
      })
      .catch(error => dispatch({ type: USER_LOGOUT_SUCCESS, error }));
  };
}

const addUser = (user, dispatch) =>
  new Promise(resolve => {
    dispatch({ type: USER_GET_SUCCESS, user });
    resolve();
  });

const newUser = id => {
  localStorage.setItem('user_id', id);

  return {
    type: USER_NEW
  };
};

const updateTrackingContext = user => {
  if (__SENTRY__) {
    const context = user.username ? { email: user.username, id: user.id } : { id: user.id };

    Sentry.configureScope(scope => {
      scope.setUser(context);
    });
  }
};

export function goToSSO(redirect = '') {
  return dispatch => {
    dispatch(push(`/sso?r=${redirect}`));
  };
}

const loginPath = '/login';

export function getCurrentUser(useRedirect = false) {
  // debugger;
  return (dispatch, getState) => {
    const appState = getState();
    const networkStatus = appState.network.status;
    const { pathname } = appState.router.location;
    const { user } = getState().user;

    if (networkStatus !== ONLINE && networkStatus !== READY) {
      if (user) {
        // console.log('sso1', moment.utc())
        return dispatch({ type: USER_SSO_CHECK, ssoComplete: moment.utc() });
      }
      dispatch({ type: USER_GET_FAIL });
      return dispatch(push('/login'));
    }

    dispatch({ type: USER_GET });
    superagent
      .get(`/exogen/api/user/`)
      .timeout({
        response: 30000, // Wait 30 seconds for the server to start sending,
        deadline: 60000 // but allow 1 minute for the file to finish loading.
      })
      .end((err, { body = null } = {}) => {
        if (!err && body && body.user) {
          if (user && body.user.hasOwnProperty('id') && body.user.id !== user.id) {
            dispatch(newUser(body.user.id));
          } else if (
            // New user added to user management
            user &&
            body.user?.groups?.includes(Groups.ExogenOrderManagementMode) &&
            !user?.groups?.includes(Groups.ExogenOrderManagementMode)
          ) {
            dispatch(newUser(body.user.id));
          } else if (
            // for readability, this is the reverse of the else if above
            user &&
            !body.user?.groups?.includes(Groups.ExogenOrderManagementMode) &&
            user?.groups?.includes(Groups.ExogenOrderManagementMode)
          ) {
            dispatch(newUser(body.user.id));
          }

          updateTrackingContext(body.user);

          addUser(body.user, dispatch).then(() => {
            localStorage.setItem('user_id', body.user.id);
            // console.log('sso2', moment.utc())
            dispatch({ type: USER_SSO_CHECK, ssoComplete: moment.utc() });
            if (useRedirect) {
              dispatch(push('/'));
            }
          });
        } else {
          console.log('error', err);
          if (pathname !== loginPath) {
            if (pathname !== '/') localStorage.setItem('deepLinkRedirect', pathname);
            dispatch(push(loginPath));
            // console.log('sso3', moment.utc())
            dispatch({ type: USER_GET_FAIL, ssoComplete: moment.utc() });
          } else {
            // console.log('sso4', moment.utc())
            dispatch({ type: USER_GET_FAIL, ssoComplete: moment.utc() });
          }
        }
      });
  };
}

export function updateUserPrefs(prefs) {
  return {
    type: USER_UPDATE_PREF,
    prefs
  };
}
