import memoize from 'memoizee';
import * as Sentry from '@sentry/browser';

const captureForSentry = (draft, message) => {
  if (__SENTRY__) {
    Sentry.configureScope(scope => {
      scope.setExtra('draft', draft);
    });
    Sentry.captureMessage(message);
  }
};

// todo: use orderLocation to avoid unnecessary searching
export const getCurrentDocusignDocuments = memoize(({ adminOrder, currentOrder }) => {
  if (currentOrder && currentOrder.docusign_documents) return currentOrder.docusign_documents;
  if (adminOrder && adminOrder.docusign_documents) return adminOrder.docusign_documents;

  return [];
});

const openStatus = ['open'];
const openOrCompletedStatus = ['open', 'invoiced', 'completed'];

export const isSapStatusOpenOrCompleted = memoize(({ currentOrder, adminOrder }) => {
  if (currentOrder) {
    return currentOrder?.sap_status
      ? openOrCompletedStatus?.includes(currentOrder?.sap_status.name)
      : false;
  }

  if (adminOrder) {
    return adminOrder.sap_status
      ? openOrCompletedStatus?.includes(adminOrder.sap_status.name)
      : false;
  }

  return false;
});

// todo: use orderLocation to avoid unnecessary searching
export const isSapStatusOpen = memoize(({ currentOrder, adminOrder }) => {
  if (currentOrder) {
    return currentOrder.sap_status ? openStatus?.includes(currentOrder.sap_status.name) : false;
  }

  if (adminOrder) {
    return adminOrder.sap_status ? openStatus?.includes(adminOrder.sap_status.name) : false;
  }

  return false;
});

const getDraftFromOrder = (currentDraft, user) => {
  if (!currentDraft || !currentDraft.order) {
    captureForSentry(currentDraft, 'Invalid draft debugging');
  }

  const draft = currentDraft?.order?.hasOwnProperty('order_notes')
    ? currentDraft.order
    : {
        ...currentDraft.order,
        order_notes: []
      };

  if (Number.isInteger(draft.physician)) delete draft.physician;

  if (
    user &&
    !draft.territory &&
    Array.isArray(user.territories) &&
    user.territories.length === 1
  ) {
    draft.territory = user.territories[0].id;
  }

  return draft;
};

export const getOmDraftOrOrder = (currentOrder, orderLocation, user) => {

  if (!currentOrder) return null;

  if (orderLocation === 'drafts') {
    return getDraftFromOrder(currentOrder, user);
  }

  return currentOrder;
};

export const isRxSapStatusOpen = memoize(({ currentOrderId, orders }) => {
  let currentOrder = orders && orders.find(order => order.id == currentOrderId);
  console.log(currentOrder);
  console.log(openStatus);
  if (currentOrder) {
    return currentOrder.sap_status ? openStatus?.includes(currentOrder.sap_status.name) : false;
  }

  return false;
});

export const getInitialRxValues = (
  currentOrder,
  orderLocation,
  { offices, physicians: physMap }
) => {
  if (!currentOrder) return null;
  const order = currentOrder && currentOrder.order ? currentOrder.order : currentOrder;

  if (
    orderLocation === 'drafts' &&
    !order.practice &&
    !order.physician &&
    offices &&
    offices.length === 1
  ) {
    const office = offices[0];
    const physicians = physMap.find(p => p.practice_id === office.practice_id);

    const physician =
      physicians && physicians.items && physicians.items.length === 1 ? physicians.items[0] : '';

    return { ...order, office, physician };
  } else {
    return order;
  }
};
