const moment = require('moment');

const baseFormFields = {
  add_payer: false,
  add_secondary_payer: false,
  add_tertiary_payer: false,
  add_physician: false,
  claim_number: '',
  claims_adjuster: '',
  claims_adjuster_phone: '',
  first_payer: null,
  first_payer_telephone: '',
  insured_if_other_relationship: null,
  insured_if_other_first_name: '',
  insured_if_other_last_name: '',
  insured_if_other_telephone: '',
  insured_if_other_employer: '',
  insured_if_other_address: '',
  insured_if_other_address_city: '',
  insured_if_other_address_state: '',
  insured_if_other_zip: '',
  insured_if_other_dob: '',
  insured_if_other_gender: '',
  primary_insurance_policy_num: '',
  primary_insurance_group_num: '',
  payer_if_other_name: '',
  payer_if_other_address: '',
  payer_if_other_address_city: '',
  payer_if_other_address_state: '',
  payer_if_other_address_zip: '',
  physician_email: '',
  second_payer_elected: '',
  second_payer: null,
  second_insurance_group_num: '',
  second_insurance_policy_num: '',
  second_payer_telephone: '',
  secondary_payer_if_other_name: '',
  secondary_payer_if_other_address: '',
  secondary_payer_if_other_address_city: '',
  secondary_payer_if_other_address_state: '',
  secondary_payer_if_other_address_zip: '',

  tertiary_payer_elected: '',
  tertiary_payer: null,
  tertiary_insurance_group_num: '',
  tertiary_insurance_policy_num: '',
  tertiary_payer_telephone: '',
  tertiary_payer_if_other_name: '',
  tertiary_payer_if_other_address: '',
  tertiary_payer_if_other_address_city: '',
  tertiary_payer_if_other_address_state: '',
  tertiary_payer_if_other_address_zip: '',

  shipping_same_as_patient: '',
  shipping_fax_physician: false,
  patient_provided_delivery_address: '',
  shipping_method: 'ground',
  shipping_product: null,
  shipping_name: '',
  shipping_address: '',
  shipping_delivery_signature_required: 'not-required',
  shipping_apt_suite: '',
  shipping_city: '',
  shipping_state: '',
  shipping_zip: '',
  shipping_notes: '',
  physician: '',
  practice: '',
  reference: '',
  insured_not_patient: false,
  documents: [],
  guideline_interpretation: '',
  patient: {
    language: 'en',
    first_name: '',
    middle_initial: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
    home_address: '',
    apt_suite: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    employer: '',
    primary_telephone: '',
    other_telephone: '',
    welcome_email_language:'english'
  },
  diagnosis: {
    additional_equipment: '',
    comorbidity_other: '',
    gap: '',
    fracture_type: null,
    fracture_location: null,
    fracture_condition: null,
    fracture_location_other_note: '',
    injury_date: '',
    surgery_date: '',
    first_xray_date: '',
    last_xray_date: '',
    special_study: false,
    rx_date: '',
    icd10_0: '',
    icd10_1: '',
    icd10_2: '',
    icd10_3: '',
    comorbidities: [],
    cause_of_injury: 3,
    multiple_views_noted: '',
    signed_record_on_file: '',
    last_xray_multiple_views_noted: '',
    last_xray_signed_record_on_file: '',
    medicare_records_confirmation: ''
  },
  order_notes: [''],
  order_type: 1,
  updated: null,
  self_pay_elected: false,
  territory: null,
  letter_of_necessity: false,
  patient_waiver: false,
  waiver_signature: false,
  __dirty: false
};

const emptyOrder = () => {
  return {
    draft_version: 1,
    created: moment.utc().format(),
    updated: moment.utc().format(),
    order: {
      ...baseFormFields,
      client_version: 1,
      created: moment.utc().format()
    }
  };
};

const getAttachedDocTypes = docs => {
  const attachedDocsSet =
    docs && Array.isArray(docs)
      ? docs.reduce((acc, elem) => {
          elem.document_types.forEach(docType => acc.add(docType.id));
          return acc;
        }, new Set())
      : new Set();

  return Array.from(attachedDocsSet);
};

const hasRequiredDocs = (reqDocs, docs, docusignDocs = []) => {
  const attachedDocs = getAttachedDocTypes(docs);
  let meetsRequirements = true;
  reqDocs.forEach(requiredDoc => {
    const hasDocType =
      attachedDocs.find(doc => doc === requiredDoc) ||
      docusignDocs.find(
        doc => doc.envelope_status === 'Signed' && doc.document_type === requiredDoc
      );
    if (!hasDocType) {
      meetsRequirements = false;
    }
  });

  return meetsRequirements;
};

module.exports = {
  baseFormFields,
  emptyOrder,
  getAttachedDocTypes,
  hasRequiredDocs
};
