import apiClient from 'exogen/utils/api-client';
import superagent from 'superagent';
import * as Sentry from '@sentry/browser';
import { REHYDRATE } from 'redux-persist/lib/constants';

import config from 'exogen/config';
import { generateUniqueId } from 'shared/utils/uuid';
import { USER_NEW } from 'exogen/redux/actions/user';

import { getMyOrders } from 'exogen/redux/actions/orders';

export const DOCUMENTS_ALL = 'DOCUMENTS_ALL';
export const DOCUMENTS_ALL_SUCCESS = 'DOCUMENTS_ALL_SUCCESS';
export const DOCUMENTS_ALL_FAIL = 'DOCUMENTS_ALL_FAIL';
export const DOCUMENT_APPROVE = 'DOCUMENT_APPROVE';
export const DOCUMENT_APPROVE_SUCCESS = 'DOCUMENT_APPROVE_SUCCESS';
export const DOCUMENT_APPROVE_FAIL = 'DOCUMENT_APPROVE_FAIL';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_FAIL = 'DOCUMENT_DELETE_FAIL';
export const DOCUMENT_DENY = 'DOCUMENT_DENY';
export const DOCUMENT_DENY_SUCCESS = 'DOCUMENT_DENY_SUCCESS';
export const DOCUMENT_DENY_FAIL = 'DOCUMENT_DENY_FAIL';
export const DOCUMENT_LINK = 'DOCUMENT_LINK';
export const DOCUMENT_LINK_SUCCESS = 'DOCUMENT_LINK_SUCCESS';
export const DOCUMENT_LINK_FAIL = 'DOCUMENT_LINK_FAIL';
export const DOCUMENT_UNLINK = 'DOCUMENT_UNLINK';
export const DOCUMENT_UNLINK_SUCCESS = 'DOCUMENT_UNLINK_SUCCESS';
export const DOCUMENT_UNLINK_FAIL = 'DOCUMENT_UNLINK_FAIL';
export const LINK_OPEN_MODAL = 'LINK_OPEN_MODAL';
export const DOCUMENT_SCAN = 'DOCUMENT_SCAN';
export const DOCUMENT_SCAN_SUCCESS = 'DOCUMENT_SCAN_SUCCESS';
export const DOCUMENT_SCAN_FAIL = 'DOCUMENT_SCAN_FAIL';
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE';
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_SUCCESS';
export const DOCUMENT_UPDATE_FAIL = 'DOCUMENT_UPDATE_FAIL';
export const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';
export const DOCUMENT_UPLOAD_FAIL = 'DOCUMENT_UPLOAD_FAIL';
export const DOCUMENT_UPLOAD_PROGRESS = 'DOCUMENT_UPLOAD_PROGRESS';
export const DOCUMENT_SCAN_PROGRESS = 'DOCUMENT_SCAN_PROGRESS';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD = 'DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD';
export const DOCUMENT_SCAN_SUCCESS_REMOVE = 'DOCUMENT_SCAN_SUCCESS_REMOVE';
export const DOCUMENT_REMOVE = 'DOCUMENT_REMOVE';
export const DOCUSIGN_CREATE = 'DOCUSIGN_CREATE';
export const DOCUSIGN_CREATE_SUCCESS = 'DOCUSIGN_CREATE_SUCCESS';
export const DOCUSIGN_CREATE_FAIL = 'DOCUSIGN_CREATE_FAIL';
export const DOCUSIGN_VOID = 'DOCUSIGN_VOID';
export const DOCUSIGN_VOID_SUCCESS = 'DOCUSIGN_VOID_SUCCESS';
export const DOCUSIGN_VOID_FAIL = 'DOCUSIGN_VOID_FAIL';
export const DOCUSIGN_RESET = 'DOCUSIGN_RESET';
export const ABN_OPEN_MODAL = 'ABN_OPEN_MODAL';
export const CMN_OPEN_MODAL = 'CMN_OPEN_MODAL';
export const CMN_EMAIL_OPEN_MODAL = 'CMN_EMAIL_OPEN_MODAL';
export const DEMO_OPEN_MODAL = 'DEMO_OPEN_MODAL';
export const ADVOCACY_OPEN_MODAL = 'ADVOCACY_OPEN_MODAL';
export const TPL_OPEN_MODAL = 'TPL_OPEN_MODAL';
export const UNLINK_OPEN_MODAL = 'UNLINK_OPEN_MODAL';
export const UPLOADS_OPEN_MODAL = 'UPLOADS_OPEN_MODAL';
export const SCAN_OPEN_MODAL = 'SCAN_OPEN_MODAL';
export const REQUEST_DOCUMENT_OPEN_MODAL = 'REQUEST_DOCUMENT_OPEN_MODAL';
export const QUICK_SIGN_CREATE = 'QUICK_SIGN_CREATE';
export const QUICK_SIGN_CREATE_SUCCESS = 'QUICK_SIGN_CREATE_SUCCESS';
export const QUICK_SIGN_CREATE_FAIL = 'QUICK_SIGN_CREATE_FAIL';

export const SET_REQUESTED_DOCUMENTS = 'SET_REQUESTED_DOCUMENTS';
export const SET_REQUESTED_DOCUMENTS_SUCCESS = 'SET_REQUESTED_DOCUMENTS_SUCCESS';
export const SET_REQUESTED_DOCUMENTS_FAIL = 'SET_REQUESTED_DOCUMENTS_FAIL';
const timeout = {
  response: 120000, // Wait 5 seconds for the server to start sending,
  deadline: 120000 // but allow 1 minute for the file to finish loading.
};

const STATUS = {
  PENDING: 'pending',
  AVAILABLE: 'available',
  UPLOADED: 'uploaded'
};

const { PENDING } = STATUS;

const initialState = {
  approvalInProgress: false,
  deletingInProgress: false,
  documentDeleteError: false,
  abnModalShow: false,
  cmnModalShow: false,
  cmnEmailModalShow: false,
  demoPairDialogShow: false,
  docusignLoading: false,
  docusignError: false,
  linkError: false,
  linkInProgress: false,
  linkRowSelected: 0,
  linkModalShow: false,
  advocacyModalShow: false,
  tplModalShow: false,
  unlinkInProgress: false,
  unlinkModalShow: false,
  unlinkRowSelected: 0,
  unlinkError: false,
  unlinkedDocuments: {
    loading: false,
    items: []
  },
  updateInProgress: false,
  updateError: false,
  uploads: [],
  uploadModalShow: false,
  scanModalShow: false,
  scanProgress: null,
  scanUploadInProgress: false,
  requestDocumentModalShow: false,
  uploadError: null
};

const captureForSentry = (action, message) => {
  if (__SENTRY__) {
    Sentry.configureScope(scope => {
      scope.setExtra('action', action);
    });
    Sentry.captureMessage(message);
  }
};

export default function reducer(state = initialState, action = {}) {
  let docIndex;
  let newDoc;

  switch (action.type) {
    case REHYDRATE:
      return action.payload && action.payload.documents
        ? {
            ...state,
            ...action.payload.documents,
            approvalInProgress: false,
            approvalError: null,
            deletingInProgress: false,
            documentDeleteError: false,
            abnModalShow: false,
            cmnModalShow: false,
            cmnEmailModalShow: false,
            demoPairDialogShow: false,
            tplModalShow: false,
            docusignLoading: false,
            docusignError: false,
            linkInProgress: false,
            linkError: false,
            linkRowSelected: 0,
            unlinkRowSelected: 0,
            linkModalShow: false,
            advocacyModalShow: false,
            unlinkModalShow: false,
            uploadModalShow: false,
            scanModalShow: false,
            requestDocumentModalShow: false,
            scanProgress: null,
            scanUploadInProgress: false,
            unlinkInProgress: false,
            unlinkError: false,
            updateInProgress: false,
            updateError: false,
            uploadError: null,
            uploads: []
          }
        : {
            ...state
          };

    case DOCUMENT_APPROVE:
    case DOCUMENT_DENY:
      return {
        ...state,
        approvalInProgress: action.id,
        approvalError: null
      };
    case DOCUMENT_APPROVE_SUCCESS:
    case DOCUMENT_DENY_SUCCESS:
      return {
        ...state,
        approvalInProgress: false,
        approvalError: null
      };
    case DOCUMENT_APPROVE_FAIL:
    case DOCUMENT_DENY_FAIL:
      return {
        ...state,
        approvalInProgress: null,
        approvalError: true
      };
    case DOCUMENT_DELETE:
      docIndex = state.unlinkedDocuments.items.findIndex(doc => doc.id === action.id);

      return {
        ...state,
        unlinkedDocuments: {
          ...state.unlinkedDocuments,
          items: [
            ...state.unlinkedDocuments.items.slice(0, docIndex),
            Object.assign({}, state.unlinkedDocuments.items[docIndex], action.document),
            ...state.unlinkedDocuments.items.slice(docIndex + 1)
          ]
        },
        deletingInProgress: true,
        documentDeleteError: false
      };
    case DOCUMENT_DELETE_SUCCESS:
      return {
        ...state,
        deletingInProgress: false,
        linkModalShow: false,
        unlinkedDocuments: {
          ...state.unlinkedDocuments,
          items: state.unlinkedDocuments.items.filter(doc => doc.id !== action.id)
        }
      };
    case DOCUMENT_DELETE_FAIL:
      return {
        ...state,
        deletingInProgress: false,
        documentDeleteError: true
      };
    case LINK_OPEN_MODAL:
      return {
        ...state,
        linkError: false,
        linkModalShow: action.show,
        linkRowSelected:
          action.selected || action.selected === 0 ? action.selected : state.linkRowSelected
      };
    case DOCUMENT_UPDATE:
      return {
        ...state,
        updateInProgress: true,
        updateError: false
      };
    case DOCUMENT_UPDATE_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        unlinkModalShow: false
      };
    case DOCUMENT_UPDATE_FAIL:
      return {
        ...state,
        updateInProgress: false,
        updateError: action.error
      };
    case DOCUMENTS_ALL:
      return {
        ...state,
        unlinkedDocuments: { ...state.unlinkedDocuments, loading: true }
      };
    case DOCUMENTS_ALL_SUCCESS:
      return {
        ...state,
        unlinkedDocuments: { items: action.result.data, loading: false }
      };
    case DOCUMENTS_ALL_FAIL:
      return {
        ...state,
        unlinkedDocuments: { ...state.unlinkedDocuments, loading: false }
      };
    case DOCUMENT_UPLOAD:
      return {
        ...state,
        uploadModalShow: false,
        uploads: [...state.uploads, action.document],
        uploadError: null
      };

    case DOCUMENT_SCAN:
      return {
        ...state,
        scanUploadInProgress: true,
        uploadError: null
      };
    case DOCUMENT_UNLINK:
      return {
        ...state,
        unlinkError: false,
        unlinkInProgress: true
      };
    case DOCUMENT_UNLINK_SUCCESS:
      return {
        ...state,
        unlinkModalShow: false,
        unlinkInProgress: false
      };
    case DOCUMENT_UNLINK_FAIL:
      return {
        ...state,
        unlinkError: action.error,
        unlinkInProgress: false
      };
    case DOCUMENT_LINK:
      return {
        ...state,
        linkError: false,
        linkInProgress: true
      };
    case DOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        linkModalShow: false,
        linkInProgress: false,
        unlinkedDocuments: {
          ...state.unlinkedDocuments,
          items: state.unlinkedDocuments.items.filter(doc => doc.id !== action.result.data.id)
        }
      };
    case DOCUMENT_LINK_FAIL:
      return {
        ...state,
        linkInProgress: false,
        linkError: action.error
      };
    case DOCUMENT_UPLOAD_PROGRESS:
      docIndex = state.uploads.findIndex(doc => doc.id === action.document.id);
      return {
        ...state,
        uploads: [
          ...state.uploads.slice(0, docIndex),
          { ...action.document },
          ...state.uploads.slice(docIndex + 1)
        ]
      };

    case DOCUMENT_SCAN_PROGRESS:
      return {
        ...state,
        scanProgress: action.percent
      };

    case DOCUMENT_SCAN_SUCCESS:
      return {
        ...state,
        scanModalShow: false,
        scanUploadInProgress: false,
        uploadModalShow: false
      };

    case DOCUMENT_SCAN_SUCCESS_REMOVE:
      return {
        ...state,
        scanProgress: null
      };
    case DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD:
      docIndex = state.uploads.findIndex(doc => doc.id === action.oldId);

      const newUploads = [
        ...state.uploads.slice(0, docIndex),
        ...state.uploads.slice(docIndex + 1)
      ];

      return {
        ...state,
        uploads: newUploads
      };
    case DOCUMENT_UPLOAD_FAIL:
      docIndex = state.uploads.findIndex(doc => doc.id === action.document.id);
      return {
        ...state,
        uploads: [...state.uploads.slice(0, docIndex), ...state.uploads.slice(docIndex + 1)],
        uploadError: action.error && action.error.message ? action.error.message : action.error
      };

    case DOCUMENT_SCAN_FAIL:
      docIndex = state.uploads.findIndex(doc => doc.id === action.document.id);
      return {
        ...state,
        scanUploadInProgress: false,
        uploadError: action.error && action.error.message ? action.error.message : action.error
      };
    case DOCUSIGN_CREATE:
      return {
        ...state,
        docusignLoading: true,
        docusignError: false
      };
    case DOCUSIGN_CREATE_SUCCESS:
      return {
        ...state,
        advocacyModalShow: false,
        abnModalShow: false,
        cmnModalShow: false,
        cmnNewModalShow: false,
        demoPairDialogShow: false,
        tplModalShow: false,
        docusignLoading: false
      };
    case DOCUSIGN_CREATE_FAIL:
      captureForSentry(action, 'Failed docusign creation');

      return {
        ...state,
        docusignLoading: false,
        docusignError: action && action.error && action.error.body && action.error.body.error
      };
    case DOCUSIGN_RESET:
      return {
        ...state,
        docusignLoading: false,
        docusignError: null
      };
    case ABN_OPEN_MODAL:
      return {
        ...state,
        abnModalShow: action.show
      };
    case CMN_EMAIL_OPEN_MODAL:
      return {
        ...state,
        cmnEmailModalShow: action.show
      };
    case CMN_OPEN_MODAL:
      return {
        ...state,
        cmnModalShow: action.show
      };
    case DEMO_OPEN_MODAL:
      return {
        ...state,
        demoPairDialogShow: action.show
      };
    case DOCUSIGN_VOID:
      return {
        ...state,
        docusignLoading: true,
        docusignError: false
      };
    case DOCUSIGN_VOID_SUCCESS:
      captureForSentry(action, 'Successful docusign void');

      return {
        ...state,
        abnModalShow: false,
        cmnModalShow: false,
        cmnEmailModalShow: false,
        docusignLoading: false
      };
    case DOCUSIGN_VOID_FAIL:
      captureForSentry(action, 'Failed docusign void');

      return {
        ...state,
        docusignLoading: false,
        docusignError: action && action.error && action.error.body && action.error.body.error
      };
    case ADVOCACY_OPEN_MODAL:
      return {
        ...state,
        advocacyModalShow: action.show
      };
    case QUICK_SIGN_CREATE:
      return {
        ...state,
        docusignLoading: true,
        docusignError: false
      };
    case QUICK_SIGN_CREATE_SUCCESS:
      return {
        ...state,
        cmnEmailModalShow: false,
        docusignLoading: false
      };
    case QUICK_SIGN_CREATE_FAIL:
      return {
        ...state,
        docusignLoading: false,
        docusignError: action && action.error && action.error.body && action.error.body.error
      };
    case UNLINK_OPEN_MODAL:
      return {
        ...state,
        unlinkError: false,
        unlinkModalShow: action.show,
        unlinkRowSelected:
          action.selected || action.selected === 0 ? action.selected : state.unlinkRowSelected
      };
    case UPLOADS_OPEN_MODAL:
      return {
        ...state,
        uploadModalShow: action.show
      };
    case SCAN_OPEN_MODAL:
      return {
        ...state,
        scanModalShow: action.show,
        scanUploadInProgress: false
      };
    case REQUEST_DOCUMENT_OPEN_MODAL:
      return {
        ...state,
        requestDocumentModalShow: action.show
      };
    case USER_NEW:
      return {
        ...state,
        unlinkedDocuments: {
          ...state.unlinkedDocuments,
          items: []
        }
      };
    case TPL_OPEN_MODAL:
      return {
        ...state,
        tplModalShow: action.show
      };
    default:
      return state;
  }
}

export function createDocusignCmn({
  orderId,
  orderLocation,
  maName,
  maEmail,
  officeId,
  maId,
  payer,
  physicianFirstName,
  physicianLastName,
  physicianEmail,
  willMaComplete
}) {
  return dispatch => {
    dispatch({
      types: [DOCUSIGN_CREATE, DOCUSIGN_CREATE_SUCCESS, DOCUSIGN_CREATE_FAIL],
      orderId,
      orderLocation,
      promise: client =>
        client.post('docusign/cmn', {
          params: {
            maEmail,
            maId,
            maName,
            officeId,
            orderId,
            payer,
            physicianEmail,
            physicianFirstName,
            physicianLastName,
            willMaComplete
          }
        })
    });
  };
}

export function createEmailCmn({ orderId, maName, maEmail, payer }) {
  return {
    types: [QUICK_SIGN_CREATE, QUICK_SIGN_CREATE_SUCCESS, QUICK_SIGN_CREATE_FAIL],
    orderId,
    promise: client =>
      client.post('documents/cms_cmn/quick-sign', {
        params: {
          orderId,
          maName,
          maEmail,
          payer
        }
      })
  };
}

export function createDocusignAbn({
  inPerson,
  orderId,
  orderLocation,
  email,
  nonunion,
  ninetyDay,
  otherCheck,
  other
}) {
  return dispatch => {
    dispatch({
      types: [DOCUSIGN_CREATE, DOCUSIGN_CREATE_SUCCESS, DOCUSIGN_CREATE_FAIL],
      orderId,
      orderLocation,

      promise: client =>
        client.post('docusign/abn', {
          params: {
            inPerson,
            orderId,
            email,
            nonunion,
            ninetyDay,
            otherCheck,
            other
          }
        })
    });
  };
}

export function createDocusignAdvocacy({
  completedBy,
  orderId,
  orderLocation,
  patientEmail,
  patientSigning,
  legalGuardianSigning,
  guardianName,
  guardianEmail
}) {
  return dispatch => {
    dispatch({
      types: [DOCUSIGN_CREATE, DOCUSIGN_CREATE_SUCCESS, DOCUSIGN_CREATE_FAIL],
      orderId,
      orderLocation,

      promise: client =>
        client.post('docusign/advocacy', {
          params: {
            completedBy,
            orderId,
            patientEmail,
            patientSigning,
            legalGuardianSigning,
            guardianName,
            guardianEmail
          }
        })
    });
  };
}

export function createDocusignDemoPair({ orderId, orderLocation, inPerson, email }) {
  return dispatch => {
    dispatch({
      types: [DOCUSIGN_CREATE, DOCUSIGN_CREATE_SUCCESS, DOCUSIGN_CREATE_FAIL],
      orderId,
      orderLocation,
      promise: client =>
        client.post('docusign/demo_pair', {
          params: {
            orderId,
            inPerson,
            email
          }
        })
    });
  };
}

export function voidDocusign({ orderId, orderLocation, envelope_id, reason }) {
  return async (dispatch, getState) => {
    dispatch({ type: DOCUSIGN_VOID });

    try {
      const result = await apiClient.post('docusign/void', { params: { envelope_id, reason } });

      dispatch({
        type: DOCUSIGN_VOID_SUCCESS,
        orderId,
        orderLocation,
        reason,
        envelope_id
      });

      dispatch(getMyOrders());
    } catch (error) {
      console.log(error);
      dispatch({ type: DOCUSIGN_VOID_FAIL });
      alert(
        'Error voiding docusign document. ' + error.body && error.body.error
          ? error.body.error
          : error.text
      );
    }
  };
}

export function resetDocusignState() {
  return {
    type: DOCUSIGN_RESET
  };
}

export function deleteDocument(id) {
  return async (dispatch, getState) => {
    dispatch({ type: DOCUMENT_DELETE, id });
    try {
      const result = await apiClient.delete(`documents/${id}/`);

      dispatch({ type: DOCUMENT_DELETE_SUCCESS, id });
    } catch (error) {
      dispatch({ type: DOCUMENT_DELETE_FAIL });
    } finally {
      dispatch(getUnlinkedDocuments());
    }
  };
}

export function getUnlinkedDocuments() {
  return {
    types: [DOCUMENTS_ALL, DOCUMENTS_ALL_SUCCESS, DOCUMENTS_ALL_FAIL],
    promise: client => client.get(`documents/`)
  };
}

export function linkDocument(id, documentId, document_types, orderLocation) {
  return async dispatch => {
    dispatch({ type: DOCUMENT_LINK, id, documentId, document_types });
    try {
      const url =
        orderLocation === 'drafts'
          ? `drafts/${id}/link/${documentId}/`
          : `orders/${id}/link/${documentId}/`;

      const result = await apiClient.post(url, { params: document_types });
      dispatch({ type: DOCUMENT_LINK_SUCCESS, id, documentId, orderLocation, result: result.body });
      // } catch (e) {
      //   captureForSentry(e, 'Document link error');
      //   alert(JSON.stringify(e));
    } catch ({ body = { error: 'Bad Request' } }) {
      const errorMessage = body && body.error ? body.error : 'Bad Request';
      dispatch({ type: DOCUMENT_LINK_FAIL, error: errorMessage });
    } finally {
      dispatch(getUnlinkedDocuments());
    }
  };
}

export function unlinkDocument(draftId, documentId) {
  return async dispatch => {
    dispatch({ type: DOCUMENT_UNLINK, draftId, documentId });
    try {
      const result = await apiClient.post(`drafts/${draftId}/unlink/${documentId}/`);
      dispatch({ type: DOCUMENT_UNLINK_SUCCESS, draftId, documentId, result: result.body });
    } catch ({ body = { error: 'Bad Request' } }) {
      const errorMessage = body && body.error ? body.error : 'Bad Request';
      dispatch({ type: DOCUMENT_UNLINK_FAIL, error: errorMessage });
    } finally {
      dispatch(getUnlinkedDocuments());
    }
  };
}

export function removeDocument(id) {
  return {
    type: DOCUMENT_REMOVE,
    id
  };
}

function removeUpload(id) {
  return {
    type: DOCUMENT_UPLOAD_SUCCESS_REMOVE_UPLOAD,
    oldId: id
  };
}

function removeScan() {
  return {
    type: DOCUMENT_SCAN_SUCCESS_REMOVE
  };
}

export function toggleDocusignAdvocacyModal(show) {
  return {
    type: ADVOCACY_OPEN_MODAL,
    show
  };
}

export function toggleUnlinkModal(show, selected) {
  return {
    type: UNLINK_OPEN_MODAL,
    show,
    selected
  };
}

export function toggleDocumentModal(show, selected) {
  return {
    type: LINK_OPEN_MODAL,
    show,
    selected
  };
}

export function toggleUploadModal(show) {
  return {
    type: UPLOADS_OPEN_MODAL,
    show
  };
}

export function toggleScanModal(show) {
  return {
    type: SCAN_OPEN_MODAL,
    show
  };
}

export function toggleRequestDocumentModal(show) {
  return {
    type: REQUEST_DOCUMENT_OPEN_MODAL,
    show
  };
}

export function toggleCmnModal(show) {
  return {
    type: CMN_OPEN_MODAL,
    show
  };
}

export function toggleEmailCmnModal(show) {
  return {
    type: CMN_EMAIL_OPEN_MODAL,
    show
  };
}

export function toggleAbnModal(show) {
  return {
    type: ABN_OPEN_MODAL,
    show
  };
}

export function toggleDemoPairDialog(show) {
  return {
    type: DEMO_OPEN_MODAL,
    show
  };
}

export function toggleTplModal(show) {
  return {
    type: TPL_OPEN_MODAL,
    show
  };
}

function uploadProgress(document) {
  return {
    type: DOCUMENT_UPLOAD_PROGRESS,
    document
  };
}

function uploadScanProgress(percent) {
  return {
    type: DOCUMENT_SCAN_PROGRESS,
    percent
  };
}

function uploadDocInit(document) {
  return {
    type: DOCUMENT_UPLOAD,
    document
  };
}

function scanDocInit(document) {
  return {
    type: DOCUMENT_SCAN,
    document
  };
}

function uploadSuccess({ document, orderId, draftId, orderLocation }) {
  return {
    type: DOCUMENT_UPLOAD_SUCCESS,
    document,
    orderId,
    draftId,
    orderLocation
  };
}

function uploadScanSuccess(document) {
  return {
    type: DOCUMENT_SCAN_SUCCESS,
    document
  };
}

function uploadFail(error, document) {
  return {
    type: DOCUMENT_UPLOAD_FAIL,
    error,
    document
  };
}

function parseDRFErrors(errors) {
  let errorMessage = '';

  if (typeof errors === 'string' || errors instanceof String) {
    return `Error: ${errors}`;
  }

  // Loop through each field in the error object
  for (const [field, messages] of Object.entries(errors)) {
    // Loop through each message for that field
    for (const message of messages) {
      // Append to the error message string
      errorMessage += `${field}: ${message}\n`;
    }
  }

  return errorMessage.trim();
}

function uploadScanFail(error, document) {
  return {
    type: DOCUMENT_SCAN_FAIL,
    error: parseDRFErrors(error),
    document
  };
}

export function updateDocument(document, draftId, documentTypes) {
  return async dispatch => {
    dispatch({ type: DOCUMENT_UPDATE });
    try {
      const result = await apiClient.patch(`documents/${document.id}/`, { params: documentTypes });
      dispatch({ type: DOCUMENT_UPDATE_SUCCESS, document, draftId, result: result.body });
    } catch ({ body = { error: 'Bad Request' } }) {
      const errorMessage = body && body.error ? body.error : 'Error updating document';
      dispatch({ type: DOCUMENT_UPDATE_FAIL, error: errorMessage });
    } finally {
      dispatch(getUnlinkedDocuments());
    }
  };
}

export function setRequestedDocuments({ orderID, requested_documentation }) {
  return async dispatch => {
    dispatch({ type: SET_REQUESTED_DOCUMENTS });
    try {
      const result = await apiClient.put(`orders/${orderID}/requested_documents/`, {
        params: { requested_documentation }
      });
      dispatch({ type: SET_REQUESTED_DOCUMENTS_SUCCESS, result: result.body });
      dispatch(toggleRequestDocumentModal(false));
    } catch ({ body = { error: 'Bad Request' } }) {
      const errorMessage = body && body.error ? body.error : 'Error updating document';
      dispatch({ type: SET_REQUESTED_DOCUMENTS_FAIL, error: errorMessage });
    }
  };
}

export function uploadDocument({
  draftId,
  description,
  file,
  original_filename,
  document_types,
  orderId,
  orderLocation,
  genFakeFile = false
}) {
  const document = {
    id: generateUniqueId(),
    file,
    description,
    original_filename
  };
  // use File API to create a blank file when testing without a file
  let fakeFile = new File(['12345'], 'fake.pdf', { type: 'application/pdf' });
  const data = new FormData();
  genFakeFile ? data.append('file', fakeFile) : data.append('file', file);
  data.append('original_filename', original_filename);
  data.append('document_types', JSON.stringify(document_types));
  data.append('description', description);
  data.append('order_id', orderId);
  data.append('draft_id', draftId);
  data.append('status', PENDING);

  return dispatch => {
    dispatch(uploadDocInit(document));

    superagent
      .post(`${config.apiSegment}/documents/`)
      .set('BIOVENTUS-USER-ID', localStorage.getItem('user_id'))
      .set('BIOVENTUS-CLIENT-ID', 'exogen-direct')
      .timeout(timeout)
      .send(data)
      .on('progress', e => {
        const percent = typeof e.percent === 'undefined' ? 100 : e.percent;
        dispatch(uploadProgress({ ...document, progress: percent }));
      })
      .end((err, result) => {
        if (!err && result && result.body && result.body.data) {
          dispatch(
            uploadSuccess({
              document: result.body.data,
              orderId,
              draftId,
              orderLocation
            })
          );
          setTimeout(() => dispatch(removeUpload(document.id)), 2000);
        } else {
          captureForSentry(err, 'Document upload error');
          if (
            err &&
            err.status === 401 &&
            err.header &&
            err.header.hasOwnProperty('user-change') &&
            err.header['user-change'] === 'true'
          ) {
            window.location.replace('/exogen-direct/app');
          }
          dispatch(uploadFail((result && result?.body?.error) || err, document));
        }
      });
  };
}

export function uploadScannedDocument({
  draftId,
  images,
  description = 'Scanned document',
  file = null,
  original_filename = 'scanned.pdf',
  documentTypes,
  orderId,
  orderLocation
}) {
  const document = {
    id: generateUniqueId(),
    file,
    description,
    original_filename
  };

  const data = new FormData();
  data.append('file', file);
  data.append('original_filename', original_filename);
  data.append('document_types', JSON.stringify(documentTypes));
  data.append('description', description);
  if (orderLocation === 'drafts') {
    data.append('draft_id', draftId);
  } else {
    data.append('order_id', orderId);
  }

  data.append('status', PENDING);

  images.forEach(image => {
    data.append('images', image);
  });

  return dispatch => {
    dispatch(scanDocInit(document));

    superagent
      .post(`${config.apiSegment}/documents/scan/`)
      .set('BIOVENTUS-USER-ID', localStorage.getItem('user_id'))
      .set('BIOVENTUS-CLIENT-ID', 'exogen-direct')
      .timeout(timeout)
      .send(data)
      .on('progress', e => {
        const percent = typeof e.percent === 'undefined' ? 100 : e.percent;
        dispatch(uploadScanProgress(percent));
      })
      .end((err, result) => {
        if (!err && result && result.body && result.body.data && !result.body?.error) {
          dispatch(
            uploadSuccess({
              document: result.body.data,
              orderId,
              draftId,
              orderLocation
            })
          );
          dispatch(uploadScanSuccess(result.body.data));
          setTimeout(() => dispatch(removeScan()), 2000);
        } else {
          captureForSentry(err, 'Document upload error');
          if (
            err &&
            err.status === 401 &&
            err.header &&
            err.header.hasOwnProperty('user-change') &&
            err.header['user-change'] === 'true'
          ) {
            window.location.replace('/exogen-direct/app');
          }
          dispatch(uploadScanFail((result && result?.body?.error) || err, document));
        }
      });
  };
}

export function createDocusignTpl({ orderId, orderLocation, patientEmail }) {
  return dispatch => {
    dispatch({
      types: [DOCUSIGN_CREATE, DOCUSIGN_CREATE_SUCCESS, DOCUSIGN_CREATE_FAIL],
      orderId,
      orderLocation,

      promise: client =>
        client.post('docusign/tpl', {
          params: {
            orderId,
            patientEmail
          }
        })
    });
  };
}
