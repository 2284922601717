import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { policyText } from '../privacy-policy-content';


export default function(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: 12 }}>
          <h3>Privacy Notice</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div dangerouslySetInnerHTML={{__html: policyText}}></div>

      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
