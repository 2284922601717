import rulesEngine from 'rules-engine';
import superagent from 'superagent';
import config from 'exogen/config';

export const RULES_ALL = 'RULES_ALL';
export const RULES_ALL_SUCCESS = 'RULES_ALL_SUCCESS';
export const RULES_ALL_FAIL = 'RULES_ALL_FAIL';

export const RULES_MODAL_TOGGLE = 'RULES_MODAL_TOGGLE';

export const RULES_EVAL_ORDER = 'RULES_EVAL_ORDER';
export const RULES_EVAL_ORDER_ERROR = 'RULES_EVAL_ORDER_ERROR';

export const RULES_SET_PAYER = 'RULES_SET_PAYER';

const rulesPromise = () => {
  return new Promise((resolve, reject) => {
    superagent.get(`${config.apiSegment}/rules/`).end((err, { body = null } = {}) => {
      if (err) {
        reject({ error: err, body });
      } else {
        resolve(body);
      }
    });
  });
};

// this gets all the rules in the backend table, note that rules are CACHED
// disable the cache in the django view for development and hard reload or you will think you're going insane
export function getAllRules() {
  return (dispatch, getState) => {
    dispatch({ type: RULES_ALL });
    rulesPromise()
      .then(body => {
        dispatch({ type: RULES_ALL_SUCCESS, result: body });
        const { payerId, groupId } = getState().rules;
        // console.log('got all the rules', body.data?.filter(val => val.rule_name == 'default_required_docs'))  
        rulesEngine.init(body.data, payerId, groupId);
      })
      .catch(({ error }) => {
        dispatch({ type: RULES_ALL_FAIL, error });
      });
  };
}

function evalError(error) {
  return {
    type: RULES_EVAL_ORDER_ERROR,
    error
  };
}

function evalSuccess(result) {
  return {
    type: RULES_EVAL_ORDER,
    result,
    meta: {
      debounce: {
        time: 1000
      }
    }
  };
}

export function setRulesPayer(newPayerId, groupId) {
  return (dispatch, getState) => {
    const { rules, payerId } = getState().rules;
    dispatch({
      type: RULES_SET_PAYER,
      payerId: newPayerId,
      groupId
    });

    if (newPayerId !== payerId) {
      rulesEngine.init(rules, newPayerId, groupId);
    }
  };
}

export function toggleValidationModal() {
  return {
    type: RULES_MODAL_TOGGLE
  };
}

export function validateOrder(order) {
  return dispatch => {
    if (rulesEngine.isInitialized()) {
      rulesEngine
        .evaluateOrder(order)
        .then(result => dispatch(evalSuccess(result)))
        .catch(err => dispatch(evalError(err)));
    } else console.warn('Attempted to validate with uninitialized rules engine');
  };
}
