export const COMORBIDITIES_ALL = 'COMORBIDITIES_ALL';
export const COMORBIDITIES_ALL_SUCCESS = 'COMORBIDITIES_ALL_SUCCESS';
export const COMORBIDITIES_ALL_FAIL = 'COMORBIDITIES_ALL_FAIL';

export const DATA_LAST_SYNCED = 'DATA_LAST_SYNCED';

export const DOCUMENT_TYPES_ALL = 'DOCUMENT_TYPES_ALL';
export const DOCUMENT_TYPES_ALL_SUCCESS = 'DOCUMENT_TYPES_ALL_SUCCESS';
export const DOCUMENT_TYPES_ALL_FAIL = 'DOCUMENT_TYPES_ALL_FAIL';

export const ICD10S_ALL = 'ICD10S_ALL';
export const ICD10S_ALL_SUCCESS = 'ICD10S_ALL_SUCCESS';
export const ICD10S_ALL_FAIL = 'ICD10S_ALL_FAIL';

export const FRACTURE_TYPES_ALL = 'FRACTURE_TYPES_ALL';
export const FRACTURE_TYPES_ALL_SUCCESS = 'FRACTURE_TYPES_ALL_SUCCESS';
export const FRACTURE_TYPES_ALL_FAIL = 'FRACTURE_TYPES_ALL_FAIL';

export const DRAFT_DELAY_REASONS_ALL = 'DRAFT_DELAY_REASONS_ALL';
export const DRAFT_DELAY_REASONS_ALL_SUCCESS = 'DRAFT_DELAY_REASONS_ALL_SUCCESS';
export const DRAFT_DELAY_REASONS_ALL_FAIL = 'DRAFT_DELAY_REASONS_ALL_FAIL';

export const FRACTURE_LOCATIONS_ALL = 'FRACTURE_LOCATIONS_ALL';
export const FRACTURE_LOCATIONS_ALL_SUCCESS = 'FRACTURE_LOCATIONS_ALL_SUCCESS';
export const FRACTURE_LOCATIONS_ALL_FAIL = 'FRACTURE_LOCATIONS_ALL_FAIL';

export const FRACTURE_CONDITIONS_ALL = 'FRACTURE_CONDITIONS_ALL';
export const FRACTURE_CONDITIONS_ALL_SUCCESS = 'FRACTURE_CONDITIONS_ALL_SUCCESS';
export const FRACTURE_CONDITIONS_ALL_FAIL = 'FRACTURE_CONDITIONS_ALL_FAIL';

export const ORDER_TYPES_ALL = 'ORDER_TYPES_ALL';
export const ORDER_TYPES_ALL_SUCCESS = 'ORDER_TYPES_ALL_SUCCESS';
export const ORDER_TYPES_ALL_FAIL = 'ORDER_TYPES_ALL_FAIL';

export const PAYERS_ALL = 'PAYERS_ALL';
export const PAYERS_ALL_SUCCESS = 'PAYERS_ALL_SUCCESS';
export const PAYERS_ALL_FAIL = 'PAYERS_ALL_FAIL';

export const MY_PAYERS_ALL = 'MY_PAYERS_ALL';
export const MY_PAYERS_ALL_SUCCESS = 'MY_PAYERS_ALL_SUCCESS';
export const MY_PAYERS_ALL_FAIL = 'MY_PAYERS_ALL_FAIL';

export const PAYER_TYPES_ALL = 'PAYER_TYPES_ALL';
export const PAYER_TYPES_ALL_SUCCESS = 'PAYER_TYPES_ALL_SUCCESS';
export const PAYER_TYPES_ALL_FAIL = 'PAYER_TYPES_ALL_FAIL';

export const PHYSICIANS_ALL = 'PHYSICIANS_ALL';
export const PHYSICIANS_ALL_SUCCESS = 'PHYSICIANS_ALL_SUCCESS';
export const PHYSICIANS_ALL_FAIL = 'PHYSICIANS_ALL_FAIL';

export const SYSTEM_CONFIG = 'SYSTEM_CONFIG';
export const SYSTEM_CONFIG_SUCCESS = 'SYSTEM_CONFIG_SUCCESS';
export const SYSTEM_CONFIG_FAIL = 'SYSTEM_CONFIG_FAIL';

export function getComorbities() {
  return {
    types: [COMORBIDITIES_ALL, COMORBIDITIES_ALL_SUCCESS, COMORBIDITIES_ALL_FAIL],
    promise: client => client.get(`comorbidities/`)
  };
}

export function getDocumentTypes() {
  return {
    types: [DOCUMENT_TYPES_ALL, DOCUMENT_TYPES_ALL_SUCCESS, DOCUMENT_TYPES_ALL_FAIL],
    promise: client => client.get(`document-types/`)
  };
}

export function getFractureTypes() {
  return {
    types: [FRACTURE_TYPES_ALL, FRACTURE_TYPES_ALL_SUCCESS, FRACTURE_TYPES_ALL_FAIL],
    promise: client => client.get(`fracture-types/`)
  };
}

export function getDraftDelayReasons() {
  return {
    types: [DRAFT_DELAY_REASONS_ALL, DRAFT_DELAY_REASONS_ALL_SUCCESS, DRAFT_DELAY_REASONS_ALL_FAIL],
    promise: client => client.get(`draft-delay-reasons/`)
  };
}

export function getFractureLocations() {
  return {
    types: [FRACTURE_LOCATIONS_ALL, FRACTURE_LOCATIONS_ALL_SUCCESS, FRACTURE_LOCATIONS_ALL_FAIL],
    promise: client => client.get(`fracture-locations/`)
  };
}

export function getFractureConditions() {
  return {
    types: [FRACTURE_CONDITIONS_ALL, FRACTURE_CONDITIONS_ALL_SUCCESS, FRACTURE_CONDITIONS_ALL_FAIL],
    promise: client => client.get(`fracture-conditions/`)
  };
}

export function getICD10s() {
  return {
    types: [ICD10S_ALL, ICD10S_ALL_SUCCESS, ICD10S_ALL_FAIL],
    promise: client => client.get(`icd10s/`)
  };
}

export function getOrderTypes() {
  return {
    types: [ORDER_TYPES_ALL, ORDER_TYPES_ALL_SUCCESS, ORDER_TYPES_ALL_FAIL],
    promise: client => client.get(`order-types/`)
  };
}

export function getPayerTypes() {
  return {
    types: [PAYER_TYPES_ALL, PAYER_TYPES_ALL_SUCCESS, PAYER_TYPES_ALL_FAIL],
    promise: client => client.get(`payer-types/`)
  };
}

export function getPayers() {
  return {
    types: [PAYERS_ALL, PAYERS_ALL_SUCCESS, PAYERS_ALL_FAIL],
    promise: client => client.get(`payers/`)
  };
}

export function getMyPayers(territory = null) {
  return {
    types: [MY_PAYERS_ALL, MY_PAYERS_ALL_SUCCESS, MY_PAYERS_ALL_FAIL],
    promise: client => client.get(`payers/my_payers/${territory ? `?territory=${territory}` : ''}`)
  };
}

export function getPhysicians(state = null, territory = null) {
  const physiciansRequest = query => {
    return {
      types: [PHYSICIANS_ALL, PHYSICIANS_ALL_SUCCESS, PHYSICIANS_ALL_FAIL],
      promise: client => client.get(`physicians/${query}`)
    };
  };

  return dispatch => {
    if (!state && !territory) {
      dispatch(physiciansRequest(''));
    } else {
      let query = '?';
      query = state ? `${query}state=${state}` : query;
      query = territory ? `${query}territory=${territory}` : query;
      dispatch(physiciansRequest(query));
    }
  };
}

export function setLastSynced() {
  return {
    type: DATA_LAST_SYNCED
  };
}

export function getSystemConfig() {
  return {
    types: [SYSTEM_CONFIG, SYSTEM_CONFIG_SUCCESS, SYSTEM_CONFIG_FAIL],
    promise: client => client.get(`config`)
  };
}

export function getApplicationData() {
  return (dispatch, getState) => {
    const userState = getState().user;

    dispatch(setLastSynced());
    dispatch(getDocumentTypes());
    dispatch(getPayerTypes());
    dispatch(getComorbities());
    dispatch(getFractureLocations());
    dispatch(getFractureTypes());
    dispatch(getFractureConditions());
    dispatch(getICD10s());
    dispatch(getOrderTypes());
    dispatch(getMyPayers());
    dispatch(getDraftDelayReasons())
  };
}
