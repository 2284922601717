const { parse } = require('date-fns');
const moment = require('moment');
const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

const email = value =>
  value &&
  !/^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    value
  )
    ? 'Invalid email address'
    : undefined;

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const maxLength1 = maxLength(1);
const maxLength2 = maxLength(2);
const maxLength5 = maxLength(5);
const maxLength8 = maxLength(8);
const maxLength12 = maxLength(12);
const maxLength15 = maxLength(15);
const maxLength20 = maxLength(20);
const maxLength32 = maxLength(32);
const maxLength48 = maxLength(48);
const maxLength50 = maxLength(50);
const maxLength60 = maxLength(60);
const maxLength64 = maxLength(64);
const maxLength100 = maxLength(100);
const maxLength1000 = maxLength(1000);

const poBoxReg = RegExp(
  /(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i
);

const poBoxWarn = val =>
  poBoxReg.test(val) ? 'Direct Shipping to a PO Box is not allowed' : undefined;

const poBoxDelayWarn = val =>
  val ? 'Entering an invalid zip code will delay order processing.' : undefined;

const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

const minLength2 = minLength(2);
const minLength4 = minLength(4);
const minLength11 = minLength(11);

const maxValue = max => value => (value && value > max ? `Must be ${max} or less` : undefined);

const maxValue99 = maxValue(99);

const minValue = min => value => (value && value < min ? `Must be at least ${min}` : undefined);

const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

const positiveNumber = value => {
  return value && (isNaN(Number(value)) || Number <= 0) ? 'Must be a number' : undefined;
};

// const date = value =>
//   !value || (value && moment(value).isValid() && moment(value).isAfter('1900-01-01'))
//     ? undefined
//     : 'Invalid date';

const date = value => {
  if (!value) return undefined;

  // Try parsing as ISO format (YYYY-MM-DD)
  let parsedDate = moment(value, 'YYYY-MM-DD', true);

  // If not valid, try parsing as MM/DD/YYYY
  if (!parsedDate.isValid()) {
    parsedDate = moment(value, 'MM/DD/YYYY', true);
  }

  if (!parsedDate.isValid()) {
    parsedDate = moment(value, 'M/DD/YYYY', true);
  }

  // Check if the date is valid and after 1900-01-01
  if (parsedDate.isValid() && parsedDate.isAfter('1900-01-01')) {
    return undefined;
  } else {
    return 'Invalid date';
  }
};

const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

const required = value => {
  return value !== null &&
    typeof value !== 'undefined' &&
    (value || value === false || value !== '')
    ? undefined
    : 'Required';
};

const zipCode = value => {
  return value === '' ||
    typeof value === 'undefined' ||
    (value && !isNaN(Number(value)) && value.length === 5)
    ? undefined
    : `Must be a five digit number`;
};

module.exports = {
  alphaNumeric,
  date,
  email,
  maxLength1,
  maxLength2,
  maxLength5,
  maxLength8,
  maxLength12,
  maxLength15,
  maxLength20,
  maxLength32,
  maxLength48,
  maxLength50,
  maxLength60,
  maxLength64,
  maxLength100,
  maxLength1000,
  minLength2,
  minLength4,
  minLength11,
  maxValue99,
  number,
  poBoxDelayWarn,
  poBoxWarn,
  positiveNumber,
  phoneNumber,
  required,
  zipCode
};
