import { REHYDRATE } from 'redux-persist/lib/constants';
import createReducer from 'shared/utils/createReducer';
import { push } from 'connected-react-router';
import _isEqual from 'lodash.isequal';
import _get from 'lodash.get';
import _merge from 'lodash.merge';
import { generateUniqueId } from 'shared/utils/uuid';

const initialState = {
  reminders: [],
  updating: [],
  modalOpen: false,
  editMode: false,
  createError: null,
  listError: null,
  updateError: null,
  createLoading: false,
  listLoading: false,
  updateLoading: false,
  updatingId: null,
};

const CREATE_REMINDER = 'CREATE_REMINDER';
const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';
const CREATE_REMINDER_FAIL = 'CREATE_REMINDER_FAIL';

const TOGGLE_REMINDER_MODAL = 'TOGGLE_REMINDER_MODAL'
const TOGGLE_REMINDER_EDIT_MODE = 'TOGGLE_REMINDER_EDIT_MODE'

const LIST_REMINDERS = 'LIST_REMINDERS'
const LIST_REMINDERS_SUCCESS = 'LIST_REMINDERS_SUCCESS'
const LIST_REMINDERS_FAIL = 'LIST_REMINDERS_FAIL'

const UPDATE_REMINDER = 'UPDATE_REMINDER'
const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS'
const UPDATE_REMINDER_FAIL = 'UPDATE_REMINDER_FAIL'



export default createReducer(initialState, {
  [REHYDRATE]: (state, action) => {
    return action.payload && action.payload.reminders
      ? {
        ...state,
        ...action.payload.reminders,
        updating: []
      }
      : {
        ...state
      };
  },
  [CREATE_REMINDER]: (state, action) => ({
    ...state,
    createLoading: true,
    createError: null,
    // remember to filter out the temporary one first
    reminders: [...state.reminders, {id: action.id, ...action.params}]

  }),
  [CREATE_REMINDER_SUCCESS]: (state, action) => {

    return {
      ...state,
      createLoading: false,
      editMode: false,
      createError: null,
      reminders: [...state.reminders?.filter(val => val.id !== action.id), {...action.result}]
    };
  },
  [CREATE_REMINDER_FAIL]: (state, action) => {

    return ({
      ...state,
      createLoading: false,
      createError: "Error creating reminder",
      reminders: [...state.reminders?.filter(val => val.id !== action.id)]
    })
  },
  [TOGGLE_REMINDER_MODAL]: (state, action) => ({
    ...state,
    modalOpen: action.show,
  }),
  [TOGGLE_REMINDER_EDIT_MODE]: (state, action) => ({
    ...state,
    editMode: action.show,
    updatingId: action.updatingId

  }),

  [LIST_REMINDERS]: (state, action) => ({
    ...state,
    listLoading: true,
    listError: null

  }),
  [LIST_REMINDERS_SUCCESS]: (state, action) => {

    return {
      ...state,
      listLoading: false,
      listError: null,
      reminders: action?.result ? action.result : []
    };
  },
  [LIST_REMINDERS_FAIL]: (state, action) => {

    return ({
      ...state,
      listLoading: false,
      listError: "Error loading reminders"
    })
  },

  [UPDATE_REMINDER]: (state, action) => ({
    // optimistically update the reminders array
    ...state,
    updating: [...state.updating, action.reminderId],
    updateError: null,
    updateLoading: true,
    reminders: action.updatedReminder ? [...state.reminders?.filter(val => val.id !== action.reminderId), action.updatedReminder] : state.reminders
  }),
  [UPDATE_REMINDER_SUCCESS]: (state, action) => {

    return {
      ...state,
      updating: state.updating.filter(id => id !== action.reminderId),
      updateError: null,
      updateLoading: state.updating?.length === 0 ? false : true,
      editMode: false,
      reminders: [...state.reminders?.filter(val => val.id !== action.reminderId), {...action.result}]
    };
  },
  [UPDATE_REMINDER_FAIL]: (state, action) => {

    return ({
      ...state,
      updating: state.updating.filter(id => id !== action.reminderId),
      updateLoading: state.updating?.length === 0 ? false : true,
      updateError: "Error updating reminder(s)",
      editMode: false,
      reminders: action.updatedReminder ? [...state.reminders?.filter(val => val.id !== action.reminderId), action.originalReminder] : state.reminders
    })
  },
});


export function createNewReminder(params) {
  return (dispatch, getState) => {
    dispatch({
      types: [CREATE_REMINDER, CREATE_REMINDER_SUCCESS, CREATE_REMINDER_FAIL],
      params,
      id: generateUniqueId(),
      promise: client =>
        client.post(`reminders/`, {
          params: {
            ...params
          }
        })
    });
  };
}

export function listReminders() {
  return (dispatch, getState) => {
    const state = getState()
    if(state.user?.user?.permissions?.includes('exogen.view_reminder') || state.user?.user?.is_superuser) {
      dispatch({
        types: [LIST_REMINDERS, LIST_REMINDERS_SUCCESS, LIST_REMINDERS_FAIL],
        promise: client =>
          client.get(`reminders/`)
      });
    }
  };
}

export function toggleReminderModal(show) {
  return {
    type: TOGGLE_REMINDER_MODAL,
    show
  };
}

export function toggleReminderEditMode(show, updatingId) {
  return {
    type: TOGGLE_REMINDER_EDIT_MODE,
    show,
    updatingId
  };
}

export function updateReminder(params) {

  const reminderId = params?.Id


  return (dispatch, getState) => {
    const state = getState()

    const originalReminder = {...state?.reminders?.reminders?.find(val => val.id === reminderId)}
    const updatedReminder = {...state?.reminders?.reminders?.find(val => val.id === reminderId), ...params}

    dispatch({
      types: [
        UPDATE_REMINDER,
        UPDATE_REMINDER_SUCCESS,
        UPDATE_REMINDER_FAIL
      ],
      reminderId,
      params,
      updatedReminder,
      originalReminder,
      promise: client => client.patch(`reminders/${reminderId}/`, {
        params: {
          ...params
        }
      })
    })
  }
}
