import { upperCaseFirstLetter } from './string';

export default function getApiError(error) {
  if (!error) return '';
  
  if (error.response && error.response.body && error.response.body.error) {
    if (
      typeof error.response.body.error === 'string' ||
      error.response.body.error instanceof String
    ) {
      if (error.response.body.error === 'Invalid recaptcha') {
        alert('Invalid or expired recaptcha.  Please try again.');
        location.reload(true);
      }
      if (error.response.body.error === "{'zip': [ErrorDetail(string='Please enter a valid zip code', code='invalid')], 'zip_edited': [ErrorDetail(string='Please enter a valid zip code', code='invalid')]}") {
        return "Server Error: Invalid zip code, please re-enter and resubmit"
      }
      return error.response.body.error;
    } else {

      const djangoErrors = error.response.body.error;
      const formatedError = Object.keys(djangoErrors).map(outerKey =>
        Object.keys(djangoErrors[outerKey]).map(innerKey => {
          return `${upperCaseFirstLetter(outerKey)} ${upperCaseFirstLetter(
            innerKey
          )}: ${djangoErrors[outerKey][innerKey].join('. ')}`;
        })
      );

      return formatedError.join('. ');
    }
  }

  if (error.body && error.body.error) {

    if (typeof error.body.error === 'string' || error.body.error instanceof String) {
      if (error.body.error === 'Invalid recaptcha') {
        alert('Invalid or expired recaptcha.  Please try again.');
        location.reload(true);
      }
      return error.body.error;
    } else {
      const djangoErrors = error.body.error;
      const formatedError = Object.keys(djangoErrors).map(outerKey =>

        Object.keys(djangoErrors[outerKey]).map(innerKey => {

          if (Array.isArray(djangoErrors[outerKey][innerKey])) {
            return `${upperCaseFirstLetter(outerKey)} ${upperCaseFirstLetter(
              innerKey
            )}: ${djangoErrors[outerKey][innerKey].join('. ')}`;
          } else {
            return `${upperCaseFirstLetter(outerKey)} ${upperCaseFirstLetter(
              innerKey
            )}: ${djangoErrors[outerKey][innerKey]}`;
          }
        })
      );

      return formatedError.join('. ');
    }
  }

  if (error.response && error.response.statusText) {

    return error.response.statusText;
  }
}
