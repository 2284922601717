import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ isStatic = false, extraStyle = {} }) => (
  <div
    className="spinner-wrapper"
    style={{ position: isStatic ? 'static' : 'fixed', ...extraStyle }}
  >
    <i className="spinner fa fa-circle-notch fa-spin fa-3x" />
  </div>
);

export const SpinnerIcon = ({ loading = true }) =>
  loading ? (
    <span>
      <FontAwesomeIcon icon="sync" fixedWidth spin />
    </span>
  ) : (
    <span>
      <FontAwesomeIcon icon="sync" fixedWidth />
    </span>
  );
